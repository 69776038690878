import React, { useState, useEffect, useReducer } from 'react';
import { Grid, Box, Avatar, Card, CardContent, CardHeader, Link, CircularProgress, Typography, TextField } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import UsersTable from '../Content/components/tables/UsersTable';
import DownloadIcon from '@mui/icons-material/Download';
import EditIcon from '@mui/icons-material/Edit';
import BlockIcon from '@mui/icons-material/Block';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { CheckCircleOutline } from '@mui/icons-material';
import Indicator from './components/Indicator';
import Modal from './components/Modal';
import UserForm from './components/forms/UserForm';
import DownloadCSVUsersForm from './components/forms/DownloadCSVUsersForm';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import DownloadCSVActivityForm from './components/forms/DownloadCSVActivityForm';
import DownloadCSVUniversitiesForm from './components/forms/DownloadCSVUniversitiesForm';
import { usersCSV, universitiesCSV, userActivityCSV } from '../utils/csvDownload';
import { createUser } from '../requests/users/createUser';
import { changePassword } from '../requests/users/changePassword';
import { suspendUser } from '../requests/users/suspendUser';
import { editUser } from '../requests/users/editUser';
import { deleteUser } from '../requests/users/deleteUser';
import { validateUserForm, validateUserPassword } from '../utils/validators';
import { getKeycloakUserActivity } from '../requests/users/getKeycloakUserActivity';
import { getComunidadesUserActivity } from '../requests/users/getComunidadesUserActivity';
import { getUserRecursosActivity } from '../requests/recursos/getUserRecursosActivity';
import { api } from '../API';
import { formatDateFromUnix, formatDateFromUnixToDayJS } from '../utils/formatters';
import UserActivityTable from './components/tables/UserActivityTable';
import UniversitiesTable from './components/tables/UniversitiesTable';
import UniversityMembersTable from './components/tables/UniversityMembersTable';
import BarChart from './components/charts/BarChart';
import PieChart from './components/charts/PieChart';
import LineChart from './components/charts/LineChart';

const Usuarios = ({
    setAlertSuccess,
    setAlertWarning,
    setAlertError,
    alerts,
    setAlerts,
    users,
    attributes,
    setAttributes,
    setUsers,
    recursos,
    activities,
    recursosDocentes,
    sessions,
    groups,
    courses,
    events,
    capsulas,
    discussions
}) => {

    const [selectedUser, setSelectedUser] = useState(null);
    const [formLoading, setFormLoading] = useState(false);
    const [createModal, setCreateModal] = useState(false);
    const [statsModal, setStatsModal] = useState(false);
    const [downloadCSVModal, setDownloadCSVModal] = useState(false);
    const [infoModal, setShowInfoModal] = useState(false);
    const [changePasswordModal, setChangePasswordModal] = useState(false);
    const [activityModal, setActivityModal] = useState(false);
    const [activityCsvModal, setActivityCsvModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [suspendModal, setSuspendModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);

    const [selectedUniversity, setSelectedUniversity] = useState(null);
    const [downloadUniversitiesCSVModal, setDownloadUniversitiesCSVModal] = useState(false);
    const [logoModal, setLogoModal] = useState(false);
    const [membersModal, setMembersModal] = useState(false);

    const [csvUniversityName, setCsvUniversityName] = useState(true);
    const [csvUniversityMembers, setCsvUniversityMembers] = useState(true);
    const [csvUniversityMembersList, setCsvUniversityMembersList] = useState(true);
    const [csvUniversityExcel, setCsvUniversityExcel] = useState(false);

    const [loadingUserActivity, setLoadingUserActivity] = useState(false);
    const [userKeycloakActivity, setUserKeycloakActivity] = useState(null);
    const [userAprendizajeProfesionalActivity, setUserAprendizajeProfesionalActivity] = useState(null);
    const [userComunidadesActivity, setUserComunidadesActivity] = useState(null);
    const [userRecursosActivity, setUserRecursosActivity] = useState(null);

    const [csvEventPlatform, setCsvEventPlatform] = useState(true);
    const [csvEventType, setCsvEventType] = useState(true);
    const [csvEventExcel, setCsvEventExcel] = useState(false);

    const [csvFirstName, setCsvFirstName] = useState(true);
    const [csvLastName, setCsvLastName] = useState(true);
    const [csvEmail, setCsvEmail] = useState(true);
    const [csvAlternateEmail, setCsvAlternateEmail] = useState(false);
    const [csvProfileImage, setCsvProfileImage] = useState(false);
    const [csvGender, setCsvGender] = useState(false);
    const [csvIsFormador, setCsvIsFormador] = useState(false);
    const [csvIsStaff, setCsvIsStaff] = useState(false);
    const [csvIsAdmin, setCsvIsAdmin] = useState(false);
    const [csvUniversity, setCsvUniversity] = useState(false);
    const [csvRegion, setCsvRegion] = useState(false);
    const [csvCareer, setCsvCareer] = useState(false);
    const [csvActive, setCsvActive] = useState(false);
    const [csvCreatedAt, setCsvCreatedAt] = useState(false);
    const [csvEvents, setCsvEvents] = useState(false);
    const [csvTotalEvents, setCsvTotalEvents] = useState(false);
    const [csvCoursesMetrics, setCsvCoursesMetrics] = useState(false);
    const [csvCapsulasMetrics, setCsvCapsulasMetrics] = useState(false);
    const [csvDiscourseMetrics, setCsvDiscourseMetrics] = useState(false);
    const [csvRecursosMetrics, setCsvRecursosMetrics] = useState(false);
    const [csvExcel, setCsvExcel] = useState(false);

    const [createErrors, setCreateErrors] = useState({});
    const [createUsername, setCreateUsername] = useState("");
    const [createEmail, setCreateEmail] = useState("");
    const [createPassword, setCreatePassword] = useState("");
    const [createPasswordConfirm, setCreatePasswordConfirm] = useState("");
    const [createFirstName, setCreateFirstName] = useState("");
    const [createLastName, setCreateLastName] = useState("");
    const [createGender, setCreateGender] = useState("none");
    const [createIsFormador, setCreateIsFormador] = useState(false);
    const [createIsStaff, setCreateIsStaff] = useState(false);
    const [createIsAdmin, setCreateIsAdmin] = useState(false);
    const [createUniversity, setCreateUniversity] = useState("none");
    const [createRegion, setCreateRegion] = useState("none");
    const [createCareer, setCreateCareer] = useState("none");
    const [createActive, setCreateActive] = useState("none");
    const [createAlternateEmail, setCreateAlternateEmail] = useState("");
    const [createUniversityCode, setCreateUniversityCode] = useState("");
    const [createUniversityName, setCreateUniversityName] = useState("");

    const [editErrors, setEditErrors] = useState([]);
    const [editEmail, setEditEmail] = useState("");
    const [editFirstName, setEditFirstName] = useState("");
    const [editLastName, setEditLastName] = useState("");
    const [editGender, setEditGender] = useState("none");
    const [editIsFormador, setEditIsFormador] = useState(false);
    const [editIsStaff, setEditIsStaff] = useState(false);
    const [editIsAdmin, setEditIsAdmin] = useState(false);
    const [editUniversity, setEditUniversity] = useState("none");
    const [editRegion, setEditRegion] = useState("none");
    const [editCareer, setEditCareer] = useState("none");
    const [editActive, setEditActive] = useState("none");
    const [editAlternateEmail, setEditAlternateEmail] = useState("");
    const [editUniversityCode, setEditUniversityCode] = useState("");
    const [editUniversityName, setEditUniversityName] = useState("");

    const [passwordErrors, setPasswordErrors] = useState({});
    const [newPassword, setNewPassword] = useState("");

    const handleOpenCreateModal = () => {
        setCreateModal(true);
    };

    const handleCloseCreateModal = () => {
        setCreateModal(false);
    };

    const handleOpenDownloadCSVModal = () => {
        setDownloadCSVModal(true);
    };

    const handleCloseDownloadCSVModal = () => {
        setDownloadCSVModal(false);
    };

    const handleOpenStatsModal = () => {
        setStatsModal(true);
    };

    const handleCloseStatsModal = () => {
        setStatsModal(false);
    };

    const handleOpenInfoModal = async (user) => {
        setSelectedUser(user);
        setLoadingUserActivity(true);
        setShowInfoModal(true);
        const response_keycloak = await getKeycloakUserActivity(api, user.id);
        const response_comunidades = await getComunidadesUserActivity(api, user.username);
        const response_recursos = await getUserRecursosActivity(user.username);
        setUserKeycloakActivity(response_keycloak.activity);
        setUserComunidadesActivity(response_comunidades.activity);
        setUserRecursosActivity(response_recursos.activity);
        setLoadingUserActivity(false);
    };

    const handleCloseInfoModal = async () => {
        setSelectedUser(null);
        setUserKeycloakActivity(null);
        setUserComunidadesActivity(null);
        setUserRecursosActivity(null);
        setShowInfoModal(false);
    };

    const handleOpenChangePasswordModal = (user) => {
        setSelectedUser(user);
        setChangePasswordModal(true);
    };

    const handleCloseChangePasswordModal = () => {
        setSelectedUser(null);
        setNewPassword("");
        setChangePasswordModal(false);
    };

    const handleOpenActivityModal = async (user) => {
        setSelectedUser(user);
        setLoadingUserActivity(true);
        setActivityModal(true);
        const response_keycloak = await getKeycloakUserActivity(api, user.id);
        const response_comunidades = await getComunidadesUserActivity(api, user.username);
        const response_recursos = await getUserRecursosActivity(user.username);
        setUserKeycloakActivity(response_keycloak.activity);
        setUserComunidadesActivity(response_comunidades.activity);
        setUserRecursosActivity(response_recursos.activity);
        setLoadingUserActivity(false);
    };

    const handleCloseActivityModal = () => {
        setSelectedUser(null);
        setUserKeycloakActivity(null);
        setUserComunidadesActivity(null);
        setActivityModal(false);
    };

    const handleOpenActivityCsvModal = async () => {
        setActivityCsvModal(true);
    }

    const handleCloseActivityCsvModal = () => {
        setActivityCsvModal(false);
    }

    const handleOpenEditModal = (user) => {
        setSelectedUser(user);
        setEditFirstName(user.firstName);
        setEditLastName(user.lastName);
        setEditEmail(user.email);
        setEditGender((user.attributes && user.attributes.gender) ? user.attributes.gender[0] : "none");
        setEditIsFormador((user.attributes && user.attributes.is_formador) ? user.attributes.is_formador[0] === "1" : false);
        setEditIsStaff((user.attributes && user.attributes.is_staff) ? user.attributes.is_staff[0] === "1" : false);
        setEditIsAdmin((user.attributes && user.attributes.is_admin) ? user.attributes.is_admin[0] === "1" : false);
        setEditUniversity((user.attributes && user.attributes.university) ? user.attributes.university[0] : "none");
        setEditRegion((user.attributes && user.attributes.region) ? user.attributes.region[0] : "none");
        setEditCareer((user.attributes && user.attributes.career) ? user.attributes.career[0] : "none");
        setEditActive(user.enabled ? "active" : "suspended");
        setEditAlternateEmail((user.attributes && user.attributes.alternateEmail) ? user.attributes.alternateEmail[0] : "");
        setEditModal(true);
    };

    const handleCloseEditModal = () => {
        setSelectedUser(null);
        setEditModal(false);
    };

    const handleOpenSuspendModal = (user) => {
        setSelectedUser(user);
        setSuspendModal(true);
    };

    const handleCloseSuspendModal = () => {
        setSelectedUser(null);
        setSuspendModal(false);
    };

    const handleOpenDeleteModal = (user) => {
        setSelectedUser(user);
        setDeleteModal(true);
    };

    const handleCloseDeleteModal = () => {
        setSelectedUser(null);
        setDeleteModal(false);
    };

    const handleOpenLogoModal = (university) => {
        setSelectedUniversity(university);
        setLogoModal(true);
    };

    const handleCloseLogoModal = () => {
        setSelectedUniversity(null);
        setLogoModal(false);
    };

    const handleOpenMembersModal = (university) => {
        setSelectedUniversity(university);
        setMembersModal(true);
    };

    const handleCloseMembersModal = () => {
        setSelectedUniversity(null);
        setMembersModal(false);
    };

    const handleOpenDownloadUniversitiesCSVModal = () => {
        setDownloadUniversitiesCSVModal(true);
    }

    const handleCloseDownloadUniversitiesCSVModal = () => {
        setDownloadUniversitiesCSVModal(false);
    }

    const handleDownloadCSV = () => {
        let downloadOptions = {
            "firstName": csvFirstName,
            "lastName": csvLastName,
            "email": csvEmail,
            "alternateEmail": csvAlternateEmail,
            "profileImage": csvProfileImage,
            "gender": csvGender,
            "isFormador": csvIsFormador,
            "isStaff": csvIsStaff,
            "isAdmin": csvIsAdmin,
            "university": csvUniversity,
            "region": csvRegion,
            "career": csvCareer,
            "active": csvActive,
            "createdAt": csvCreatedAt,
            "events": csvEvents,
            "totalEvents": csvTotalEvents,
            "coursesMetrics": csvCoursesMetrics,
            "capsulasMetrics": csvCapsulasMetrics,
            "discourseMetrics": csvDiscourseMetrics,
            "recursosMetrics": csvRecursosMetrics,
        }
        usersCSV(csvExcel, users, events, groups, courses, capsulas, userKeycloakActivity, userComunidadesActivity, userRecursosActivity, downloadOptions);
        handleCloseDownloadCSVModal();
        setAlertSuccess(true);
        setAlerts({ ...alerts, "success": { "title": "¡Archivo descargado!", "text": "Los datos se han descargado correctamente." } });
    }

    const handleDownloadUniversitiesCSV = () => {
        let downloadOptions = {
            "name": csvUniversityName,
            "members": csvUniversityMembers,
            "membersList": csvUniversityMembersList
        }
        universitiesCSV(csvUniversityExcel, users, attributes, downloadOptions);
        handleCloseDownloadUniversitiesCSVModal();
        setAlertSuccess(true);
        setAlerts({ ...alerts, "success": { "title": "¡Archivo descargado!", "text": "Los datos se han descargado correctamente." } });
    }

    const handleDownloadActivityCSV = () => {
        let downloadOptions = {
            "platform": csvEventPlatform,
            "type": csvEventType
        }
        userActivityCSV(csvEventExcel, userKeycloakActivity, userComunidadesActivity, userRecursosActivity, selectedUser.username, downloadOptions);
        handleCloseDownloadCSVModal();
        setAlertSuccess(true);
        setAlerts({ ...alerts, "success": { "title": "¡Archivo descargado!", "text": "Los datos se han descargado correctamente." } });
    }

    const handleCreateUser = async () => {
        const [isValid, tempErrors] = validateUserForm(
            createUsername,
            createEmail,
            createPassword,
            createPasswordConfirm,
            createFirstName,
            createLastName,
            createActive,
            createUniversity,
            createAlternateEmail,
            createUniversityCode,
            createUniversityName
        );
        if (!isValid) {
            setCreateErrors(tempErrors);
            return;
        }
        setFormLoading(true);
        let createOptions = {
            "username": createUsername,
            "email": createEmail,
            "password": createPassword,
            "firstName": createFirstName,
            "lastName": createLastName,
            "enabled": createActive === "active" ? true : false,
            "gender": createGender,
            "isFormador": createIsFormador,
            "isStaff": createIsStaff,
            "isAdmin": createIsAdmin,
            "university": createUniversity !== "none" ? createUniversity : null,
            "region": createRegion !== "none" ? createRegion : null,
            "career": createCareer !== "none" ? createCareer : null,
            "alternateEmail": createAlternateEmail !== "" ? createAlternateEmail : null,
            "universityCode": createUniversityCode !== "" ? createUniversityCode : null,
            "universityName": createUniversityName !== "" ? createUniversityName : null
        }
        const response_create = await createUser(api, createOptions);
        if (response_create.users != null) {
            handleCloseCreateModal();
            clearCreateForm();
            setUsers(response_create.users);
            setAttributes(response_create.attributes);
            if (response_create.errors.created_openedx_error !== "" || response_create.errors.created_discourse_error !== "") {
                setAlertWarning(true);
                let warningError = "";
                if (response_create.errors.created_openedx_error !== "") {
                    warningError += "\nOpen edX: " + response_create.errors.created_openedx_error + ". ";
                }
                if (response_create.errors.created_discourse_error !== "") {
                    warningError += "\nDiscourse: " + response_create.errors.created_discourse_error + ". ";
                }
                setAlerts({
                    ...alerts,
                    "warning": {
                        "title": "Usuario creado parcialmente",
                        "text": "Hubo un error al crear el usuario en las siguientes plataformas: " + warningError
                    }
                });
            } else {
                setAlertSuccess(true);
                setAlerts({ ...alerts, "success": { "title": `¡Usuario creado!`, "text": `El usuario ${createUsername} se ha creado correctamente.` } });
            }
        } else {
            setAlertError(true);
            setAlerts({ ...alerts, "error": { "title": "Error al crear usuario", "text": response_create.error } });
        }
        setFormLoading(false);
    }

    const handleChangePassword = async () => {
        const [isValid, tempErrors] = validateUserPassword(
            newPassword
        );
        if (!isValid) {
            setPasswordErrors(tempErrors);
            return;
        }
        setFormLoading(true);
        const response = await changePassword(api, selectedUser.id, selectedUser.username, newPassword);
        if (response.error === "") {
            setAlertSuccess(true);
            setAlerts({ ...alerts, "success": { "title": "¡Contraseña cambiada!", "text": "La contraseña del usuario " + selectedUser.username + " ha sido cambiada correctamente." } });
            handleCloseChangePasswordModal();
        } else {
            setAlertError(true);
            setAlerts({ ...alerts, "error": { "title": "Error al cambiar contraseña", "text": response.error } });
        }
        setFormLoading(false);
    }

    const handleEditUser = async () => {
        let editOptions = {
            "user_id": selectedUser.id,
            "username": selectedUser.username,
            "email": editEmail,
            "firstName": editFirstName,
            "lastName": editLastName,
            "gender": editGender,
            "isFormador": editIsFormador,
            "isStaff": editIsStaff,
            "isAdmin": editIsAdmin,
            "university": editUniversity,
            "region": editRegion,
            "career": editCareer,
            "enabled": editActive === "active" ? true : false,
            "alternateEmail": editAlternateEmail,
            "universityCode": editUniversityCode !== "" ? editUniversityCode : null,
            "universityName": editUniversityName !== "" ? editUniversityName : null
        }
        setFormLoading(true);
        const response = await editUser(api, editOptions);
        if (response.users != null) {
            setUsers(response.users);
            setAttributes(response.attributes);
            handleCloseEditModal();
            if (response.errors.edited_openedx_error !== "" || response.errors.edited_discourse_error !== "") {
                setAlertWarning(true);
                let warningError = "";
                if (response.errors.edited_openedx_error !== "") {
                    warningError += "Open edX: " + response.errors.edited_openedx_error + ". ";
                }
                if (response.errors.edited_discourse_error !== "") {
                    warningError += "Discourse: " + response.errors.edited_discourse_error + ". ";
                }
                setAlerts({
                    ...alerts,
                    "warning": {
                        "title": "Usuario editado parcialmente",
                        "text": "Hubo un error al editar el usuario en las siguientes plataformas: " + warningError
                    }
                });
            } else {
                setAlertSuccess(true);
                setAlerts({ ...alerts, "success": { "title": `¡Usuario editado!`, "text": `Los datos del usuario ${selectedUser.username} han sido editados correctamente correctamente.` } });
            }
        } else {
            setAlertError(true);
            setAlerts({ ...alerts, "error": { "title": "Error al editar usuario", "text": response.error } });
        }
        setFormLoading(false);
    }

    const handleSuspendUser = async () => {
        setFormLoading(true);
        const response = await suspendUser(api, selectedUser.id, !selectedUser.enabled);
        let username = selectedUser.username;
        let action = selectedUser.enabled ? "suspendido" : "activado";
        if (response.users != null) {
            setUsers(response.users);
            handleCloseSuspendModal();
            setAlertSuccess(true);
            setAlerts({ ...alerts, "success": { "title": `¡Usuario ${action}!`, "text": `El usuario ${username} ha sido ${action} correctamente.` } });
        } else {
            setAlertError(true);
            setAlerts({ ...alerts, "error": { "title": `Error al ${action === "suspendido" ? "suspender" : "activar"} usuario`, "text": response.error } });
        }
        setFormLoading(false);
    }

    const handleDeleteUser = async () => {
        let deleteOptions = {
            "username": selectedUser.username,
            "user_id": selectedUser.id
        }
        setFormLoading(true);
        const response = await deleteUser(api, deleteOptions.user_id, deleteOptions.username);
        if (response.users != null) {
            setUsers(response.users);
            setAttributes(response.attributes);
            handleCloseDeleteModal();
            if (response.errors.deleted_openedx_error !== "" || response.errors.deleted_discourse_error !== "") {
                setAlertWarning(true);
                let warningError = "";
                if (response.errors.deleted_openedx_error !== "") {
                    warningError += "Open edX: " + response.errors.deleted_openedx_error + ". ";
                }
                if (response.errors.deleted_discourse_error !== "") {
                    warningError += "Discourse: " + response.errors.deleted_discourse_error + ". ";
                }
                setAlerts({
                    ...alerts,
                    "warning": {
                        "title": "Usuario eliminado parcialmente",
                        "text": "Hubo un error al eliminar el usuario en las siguientes plataformas: " + warningError
                    }
                });
            } else {
                setAlertSuccess(true);
                setAlerts({ ...alerts, "success": { "title": `¡Usuario eliminado!`, "text": `El usuario ${createUsername} y todos sus datos asociados han sido eliminados correctamente.` } });
            }
        } else {
            setAlertError(true);
            setAlerts({ ...alerts, "error": { "title": "Error al eliminar usuario", "text": response.error } });
        }
        setFormLoading(false);
    }

    const clearCreateForm = () => {
        setCreateUsername("");
        setCreateEmail("");
        setCreatePassword("");
        setCreatePasswordConfirm("");
        setCreateFirstName("");
        setCreateLastName("");
        setCreateGender("none");
        setCreateIsFormador(false);
        setCreateIsStaff(false);
        setCreateIsAdmin(false);
        setCreateUniversity("none");
        setCreateRegion("none");
        setCreateCareer("none");
        setCreateActive("none");
        setCreateAlternateEmail("");
        setCreateUniversityCode("");
        setCreateUniversityName("");
        setCreateErrors({});
    }

    return (
        <>
            <Grid spacing={2} container display={"flex"} justifyContent={"center"} alignItems={"center"}>
                <Grid item xs={2}>
                    <Indicator title="Total cuentas" number={
                        users.length
                    } titleSize="small" subtitleSize="none" helpMessage={
                        "Número total de cuentas de usuario en RedFID."
                    }></Indicator>
                </Grid>
                <Grid item xs={2}>
                    <Indicator title="Cuentas activas" number={
                        users.filter(user => user.enabled).length
                    } titleSize="small" subtitleSize="none" helpMessage={
                        "Número total de cuentas activas (es decir, no suspendidas) en RedFID."
                    }></Indicator>
                </Grid>
                <Grid item xs={2}>
                    <Indicator title="Formadores" number={
                        users.filter(user => user.enabled && user.attributes?.is_formador?.[0] === "1").length
                    } titleSize="small" subtitleSize="none" helpMessage={
                        "Número total de usuarios activos en RedFID con el rol de formador."
                    }></Indicator>
                </Grid>
                <Grid item xs={2}>
                    <Indicator title="Staff" number={
                        users.filter(user => user.enabled && user.attributes?.is_staff?.[0] === "1").length
                    } titleSize="small" subtitleSize="none" helpMessage={
                        "Número total de usuarios activos en RedFID con el rol de staff."
                    }></Indicator>
                </Grid>
                <Grid item xs={2}>
                    <Indicator title="Administradores" number={
                        users.filter(user => user.enabled && user.attributes?.is_admin?.[0] === "1").length
                    } titleSize="small" subtitleSize="none" helpMessage={
                        "Número total de usuarios activos en RedFID con el rol de administrador."
                    }></Indicator>
                </Grid>
            </Grid>
            <Grid spacing={2} container display={"flex"} justifyContent={"center"} alignItems={"center"}>
                <UsersTable
                    users={users}
                    attributes={attributes}
                    handleOpenCreateModal={handleOpenCreateModal}
                    handleOpenDownloadCSVModal={handleOpenDownloadCSVModal}
                    handleOpenStatsModal={handleOpenStatsModal}
                    handleOpenInfoModal={handleOpenInfoModal}
                    handleOpenActivityModal={handleOpenActivityModal}
                    handleOpenChangePasswordModal={handleOpenChangePasswordModal}
                    handleOpenEditModal={handleOpenEditModal}
                    handleOpenSuspendModal={handleOpenSuspendModal}
                    handleOpenDeleteModal={handleOpenDeleteModal}
                />
            </Grid>
            <Grid spacing={2} container display={"flex"} justifyContent={"center"} alignItems={"center"}>
                <Grid item xs={2}>
                    <Indicator title="Universidades" number={
                        [...new Set(users.filter(user => user.attributes?.is_formador?.[0] === "1" && user.attributes.university?.[0] && !["none", "no"].includes(user.attributes.university[0])).map(user => user.attributes.university[0]))].length
                    } titleSize="small" subtitleSize="none" helpMessage={
                        "Número total de universidades con usuarios con cuentas en RedFID."
                    }></Indicator>
                </Grid>
                <Grid item xs={4}>
                    <Indicator title="Universidades con miembros activos" number={
                        [...new Set(users.filter(user => user.enabled && user.attributes?.is_formador?.[0] === "1" && user.attributes.university?.[0] && !["none", "no"].includes(user.attributes.university[0])).map(user => user.attributes.university[0]))].length
                    } titleSize="small" subtitleSize="none" helpMessage={
                        "Número total de universidades con usuarios con cuentas activas en RedFID."
                    }></Indicator>
                </Grid>
            </Grid>
            <Grid spacing={2} container display={"flex"} justifyContent={"center"} alignItems={"center"}>
                <UniversitiesTable
                    users={users}
                    attributes={attributes}
                    handleOpenLogoModal={handleOpenLogoModal}
                    handleOpenMembersModal={handleOpenMembersModal}
                    handleOpenDownloadCSVModal={handleOpenDownloadUniversitiesCSVModal}
                />
            </Grid>
            <Modal
                open={createModal}
                handleClose={handleCloseCreateModal}
                title="Crear usuario"
                onClickMainButton={handleCreateUser}
                mainButtonText="Crear"
                mainButtonDisabled={formLoading}
                width="lg"
                cancelButton={true}
                startIcon={<AddCircleOutlineIcon />}
                content={
                    <>
                        <Typography variant="p" mb={"20px"}>
                            Aquí puede crear cuentas de usuarios para RedFID. Cada cuenta tiene acceso a todos los ambientes de RedFID: Aprendizaje
                            Profesional, Comunidades y Recursos. Además, se les proporcionará acceso al calendario de eventos, y, en caso de ser
                            marcados como Administradores, a este y otros paneles de administración.
                        </Typography>
                        <UserForm
                            username={createUsername}
                            setUsername={setCreateUsername}
                            email={createEmail}
                            setEmail={setCreateEmail}
                            password={createPassword}
                            setPassword={setCreatePassword}
                            passwordConfirm={createPasswordConfirm}
                            setPasswordConfirm={setCreatePasswordConfirm}
                            firstName={createFirstName}
                            setFirstName={setCreateFirstName}
                            lastName={createLastName}
                            setLastName={setCreateLastName}
                            gender={createGender}
                            setGender={setCreateGender}
                            isFormador={createIsFormador}
                            setIsFormador={setCreateIsFormador}
                            isStaff={createIsStaff}
                            setIsStaff={setCreateIsStaff}
                            isAdmin={createIsAdmin}
                            setIsAdmin={setCreateIsAdmin}
                            university={createUniversity}
                            setUniversity={setCreateUniversity}
                            region={createRegion}
                            setRegion={setCreateRegion}
                            career={createCareer}
                            setCareer={setCreateCareer}
                            active={createActive}
                            setActive={setCreateActive}
                            alternateEmail={createAlternateEmail}
                            setAlternateEmail={setCreateAlternateEmail}
                            universityCode={createUniversityCode}
                            setUniversityCode={setCreateUniversityCode}
                            universityName={createUniversityName}
                            setUniversityName={setCreateUniversityName}
                            errors={createErrors}
                            setErrors={setCreateErrors}
                            loading={formLoading}
                            attributes={attributes}
                            editing={false}
                        />
                    </>
                }
            ></Modal>
            <Modal
                open={downloadCSVModal}
                handleClose={handleCloseDownloadCSVModal}
                title="Descargar datos"
                onClickMainButton={handleDownloadCSV}
                mainButtonText="Descargar"
                mainButtonDisabled={formLoading}
                width="lg"
                cancelButton={true}
                startIcon={<DownloadIcon />}
                content={
                    <>
                        <Typography variant="p" mb={"20px"}>
                            Se descargará un archivo en formato .csv con la información de todos los usuarios de RedFID.
                            Por favor, seleccione los campos que desea incluir:
                        </Typography>
                        <DownloadCSVUsersForm
                            csvFirstName={csvFirstName}
                            setCsvFirstName={setCsvFirstName}
                            csvLastName={csvLastName}
                            setCsvLastName={setCsvLastName}
                            csvEmail={csvEmail}
                            setCsvEmail={setCsvEmail}
                            csvAlternateEmail={csvAlternateEmail}
                            setCsvAlternateEmail={setCsvAlternateEmail}
                            csvProfileImage={csvProfileImage}
                            setCsvProfileImage={setCsvProfileImage}
                            csvGender={csvGender}
                            setCsvGender={setCsvGender}
                            csvIsFormador={csvIsFormador}
                            setCsvIsFormador={setCsvIsFormador}
                            csvIsStaff={csvIsStaff}
                            setCsvIsStaff={setCsvIsStaff}
                            csvIsAdmin={csvIsAdmin}
                            setCsvIsAdmin={setCsvIsAdmin}
                            csvUniversity={csvUniversity}
                            setCsvUniversity={setCsvUniversity}
                            csvRegion={csvRegion}
                            setCsvRegion={setCsvRegion}
                            csvCareer={csvCareer}
                            setCsvCareer={setCsvCareer}
                            csvActive={csvActive}
                            setCsvActive={setCsvActive}
                            csvCreatedAt={csvCreatedAt}
                            setCsvCreatedAt={setCsvCreatedAt}
                            csvEvents={csvEvents}
                            setCsvEvents={setCsvEvents}
                            csvTotalEvents={csvTotalEvents}
                            setCsvTotalEvents={setCsvTotalEvents}
                            csvCoursesMetrics={csvCoursesMetrics}
                            setCsvCoursesMetrics={setCsvCoursesMetrics}
                            csvCapsulasMetrics={csvCapsulasMetrics}
                            setCsvCapsulasMetrics={setCsvCapsulasMetrics}
                            csvDiscourseMetrics={csvDiscourseMetrics}
                            setCsvDiscourseMetrics={setCsvDiscourseMetrics}
                            csvRecursosMetrics={csvRecursosMetrics}
                            setCsvRecursosMetrics={setCsvRecursosMetrics}
                            csvExcel={csvExcel}
                            setCsvExcel={setCsvExcel}
                        />
                    </>
                }
            ></Modal>
            <Modal
                open={statsModal}
                handleClose={handleCloseStatsModal}
                title="Estadísticas de usuarios"
                onClickMainButton={handleCloseStatsModal}
                mainButtonText="OK"
                hideMainButton={true}
                width="lg"
                cancelButton={false}
                content={
                    <>
                        <Grid display={"flex"} alignItems={"center"} justifyContent={"space-between"} columnSpacing={2} container>
                            <Grid item xs={12} mt="10px">
                                <Typography variant="h6">Formadores por universidad</Typography>
                                <BarChart
                                    id={"users-by-university"}
                                    sort={true}
                                    horizontal={false}
                                    height={"300px"}
                                    labelAngle={-45}
                                    data={() => {
                                        let out = [];
                                        for (let user of users) {
                                            if (!user.attributes) {
                                                continue;
                                            }
                                            if (!user.attributes.is_formador || !user.attributes.university) {
                                                continue;
                                            }
                                            if (!user.attributes.is_formador[0]) {
                                                continue;
                                            }
                                            if (user.attributes.university[0] === "none" || user.attributes.university[0] === "no") {
                                                continue;
                                            }
                                            let university = out.find(university => university.label === user.attributes.university[0]);
                                            if (university) {
                                                university.value++;
                                            } else {
                                                out.push({
                                                    "label": user.attributes.university[0],
                                                    "value": 1,
                                                    "color": "#40b4ba"
                                                });
                                            }
                                        }
                                        return out;
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6} mt={"10px"}>
                                <Typography variant="h6">Formadores por género</Typography>
                                <PieChart
                                    id={"users-by-gender"}
                                    height={"250px"}
                                    data={() => {
                                        let out = [];
                                        for (let user of users) {
                                            if (!user.attributes) {
                                                continue;
                                            }
                                            if (!user.attributes.is_formador || !user.attributes.gender) {
                                                continue;
                                            }
                                            if (!user.attributes.is_formador[0]) {
                                                continue;
                                            }
                                            if (user.attributes.gender[0] === "none" || user.attributes.gender[0] === "") {
                                                continue;
                                            }
                                            let gender = out.find(gender => gender.label === attributes["gender"]["options"][user.attributes.gender[0]]);
                                            if (gender) {
                                                gender.value++;
                                            } else {
                                                out.push({
                                                    "label": attributes["gender"]["options"][user.attributes.gender[0]],
                                                    "value": 1,
                                                    "color": user.attributes.gender[0] === "male" ? "#40b4ba" : user.attributes.gender[0] === "female" ? "#eb947e" : "#595959",
                                                });
                                            }
                                        }
                                        return out;
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6} mt={"10px"}>
                                <Typography variant="h6">Formadores por carrera</Typography>
                                <PieChart
                                    id={"users-by-career"}
                                    height={"250px"}
                                    data={() => {
                                        let out = [];
                                        for (let user of users) {
                                            if (!user.attributes) {
                                                continue;
                                            }
                                            if (!user.attributes.is_formador || !user.attributes.career) {
                                                continue;
                                            }
                                            if (!user.attributes.is_formador[0]) {
                                                continue;
                                            }
                                            if (user.attributes.career[0] === "none" || user.attributes.career[0] === "") {
                                                continue;
                                            }
                                            let career = out.find(career => career.label === attributes["career"]["options"][user.attributes.career[0]]);
                                            if (career) {
                                                career.value++;
                                            } else {
                                                out.push({
                                                    "label": attributes["career"]["options"][user.attributes.career[0]],
                                                    "value": 1,
                                                    "color": user.attributes.career[0] === "basica" ? "#40b4ba" : user.attributes.career[0] === "media" ? "#eb947e" : user.attributes.career[0] === "basicamedia" ? "#e4d876" : user.attributes.career[0] === "parvularia" ? "#bc7ee1" : "#595959",
                                                });
                                            }
                                        }
                                        return out;
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} mt={"10px"}>
                                <Typography variant="h6">Formadores nuevos por semana</Typography>
                                <LineChart
                                    id={"new-users-by-day"}
                                    height={"300px"}
                                    timeInterval={"week"}
                                    tooltipFormatter={(value) => value + " usuarios"}
                                    data={() => {
                                        let out = [];
                                        for (let user of users) {
                                            if (!user.createdTimestamp) {
                                                continue;
                                            }
                                            if (!user.attributes) {
                                                continue;
                                            }
                                            if (!user.attributes.is_formador) {
                                                continue;
                                            }
                                            if (user.attributes.is_formador[0] !== "1") {
                                                continue;
                                            }
                                            let date = new Date(user.createdTimestamp).toISOString().slice(0, 10).replace(/-/g, "");
                                            let day = out.find(day => day.date === date);
                                            if (day) {
                                                day.value++;
                                            } else {
                                                out.push({
                                                    "date": date,
                                                    "value": 1,
                                                    "color": "#eb947e"
                                                });
                                            }
                                        }
                                        out = out.sort((a, b) => a.date - b.date);
                                        return out;
                                    }}
                                ></LineChart>
                            </Grid>
                        </Grid>
                    </>
                }
            ></Modal>
            {selectedUser &&
                <>
                    <Modal
                        open={infoModal}
                        handleClose={handleCloseInfoModal}
                        title="Información detallada"
                        onClickMainButton={handleCloseInfoModal}
                        mainButtonText="OK"
                        hideMainButton={true}
                        width="lg"
                        cancelButton={false}
                        content={
                            <Grid container columnSpacing={2}>
                                <Grid item xs={12} md={6} display={"flex"} flexDirection={"column"} justifyContent={"space-between"}>
                                    <Grid container columnSpacing={2} alignItems="start">
                                        <Grid item xs={12} sm={4} display={"flex"} justifyContent={"center"} mb={"20px"}>
                                            {selectedUser.discourse_data == null ? <Avatar alt="Foto" style={{ marginTop: "5px", width: 120, height: 120, borderRadius: 50 }} /> : selectedUser.discourse_data.avatar_template == null ? <Avatar alt="Foto" style={{ marginTop: "5px", width: 120, height: 120, borderRadius: 50 }} /> : <Avatar src={process.env.REACT_APP_COMUNIDADES_URL + selectedUser.discourse_data.avatar_template.replace("{size}", "144")} alt="Foto" style={{ marginTop: "5px", width: 120, height: 120, borderRadius: 50 }} />}
                                        </Grid>
                                        <Grid item xs={12} sm={8} mb={"20px"}>
                                            <Grid container columnSpacing={2} alignItems="center">
                                                <Grid item xs={12}>
                                                    <Typography><b>Usuario: </b>{selectedUser.username}</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography><b>Nombre: </b>{selectedUser.firstName}</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography><b>Apellido: </b>{selectedUser.lastName}</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography><b>Activo: </b>{selectedUser.suspended ? "No" : "Sí"}</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography><b>Email principal: </b><Link color={"#40b4ba"} href={"mailto:" + selectedUser.email}>{selectedUser.email}</Link></Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography style={{ "textAlign": "justify" }}><b>Género: </b>{(selectedUser.attributes && selectedUser.attributes.gender && selectedUser.attributes.gender[0] !== "none") ? attributes["gender"]["options"][selectedUser.attributes.gender[0]] : "—"}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography style={{ "textAlign": "justify" }}><b>Programa: </b>{(selectedUser.attributes && selectedUser.attributes.career && selectedUser.attributes.career[0] !== "none") ? attributes["career"]["options"][selectedUser.attributes.career[0]] : "—"}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography style={{ "textAlign": "justify" }}><b>Región: </b>{(selectedUser.attributes && selectedUser.attributes.region && selectedUser.attributes.region[0] !== "none") ? attributes["region"]["options"][selectedUser.attributes.region[0]] : "—"}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography style={{ "textAlign": "justify" }}><b>Universidad: </b>{(selectedUser.attributes && selectedUser.attributes.university && selectedUser.attributes.university[0] !== "none") ? attributes["university"]["options"][selectedUser.attributes.university[0]] : "—"}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography><b>Email alternativo: </b>{selectedUser.attributes && selectedUser.attributes.alternateEmail && selectedUser.attributes.alternateEmail[0] !== "" ? <Link color={"#40b4ba"} href={"mailto:" + selectedUser.attributes.alternateEmail[0]}>{selectedUser.attributes.alternateEmail[0]}</Link> : "—"}</Typography>
                                        </Grid>
                                        <Grid item xs={12} mt="20px">
                                            <Typography style={{ "textAlign": "justify" }}><b>Se unió a RedFID: </b>{formatDateFromUnix(selectedUser.createdTimestamp)}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography style={{ "textAlign": "justify" }}><b>Última sesión: </b>{!userKeycloakActivity ? <></> : userKeycloakActivity.length === 0 ? "—" : formatDateFromUnix(userKeycloakActivity[0].time)}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Card sx={{
                                        "fontFamily": "Avenir Regular",
                                        "color": "#646464",
                                        "marginTop": "20px"
                                    }}>
                                        <CardHeader titleTypographyProps={{ "variant": "table-title" }} title="Eventos" />
                                        <CardContent>
                                            {events ?
                                                <Grid container columnSpacing={2} display={"flex"} justifyContent={"space-between"}>
                                                    <Grid item xs={12} sm={6}>
                                                        <Typography ml={"10px"}>
                                                            <b>Eventos asistidos: </b>
                                                            {events.filter(e => e.attendants.includes(selectedUser.username)).length}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                                :
                                                <Box textAlign={"center"}><CircularProgress sx={{ "color": "#40b4ba" }}></CircularProgress></Box>
                                            }
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Card sx={{
                                        "fontFamily": "Avenir Regular",
                                        "color": "#646464",
                                        "marginTop": "20px"
                                    }}>
                                        <CardHeader titleTypographyProps={{ "variant": "table-title" }} title="Aprendizaje Profesional" />
                                        <CardContent>
                                            {courses ?
                                                <Grid container columnSpacing={2} display={"flex"} justifyContent={"space-between"}>
                                                    <Grid item xs={12} sm={6}>
                                                        <Typography ml={"10px"}>
                                                            <b>Cursos inscritos: </b>
                                                            {
                                                                Object.values(courses).reduce((acc, course) =>
                                                                    acc + Object.values(course.sections).reduce((sectionAcc, section) => {
                                                                        if (!section.data) {
                                                                            return sectionAcc;
                                                                        }
                                                                        return section.data.enrolled.includes(selectedUser.username) ? sectionAcc + 1 : sectionAcc;
                                                                    }, 0)
                                                                    , 0)
                                                            }
                                                        </Typography>
                                                        <Typography ml={"10px"}>
                                                            <b>Cursos aprobados: </b>
                                                            {
                                                                Object.values(courses).reduce((acc, course) =>
                                                                    acc + Object.values(course.sections).reduce((sectionAcc, section) => {
                                                                        if (!section.data) {
                                                                            return sectionAcc;
                                                                        }
                                                                        return section.data.approved.includes(selectedUser.username) ? sectionAcc + 1 : sectionAcc;
                                                                    }, 0)
                                                                    , 0)
                                                            }
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <Typography ml={"10px"}>
                                                            <b>Talleres asistidos: </b>
                                                            {events.filter(e => (e.associated !== 0 && e.attendants.includes(selectedUser.username))).length}
                                                        </Typography>
                                                        <Typography ml={"10px"}>
                                                            <b>Cápsulas publicadas: </b>
                                                            {capsulas.capsulas.filter(c => c.username === selectedUser.username).length}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                                :
                                                <Box textAlign={"center"}><CircularProgress sx={{ "color": "#40b4ba" }}></CircularProgress></Box>
                                            }
                                        </CardContent>
                                    </Card>
                                    <Card sx={{
                                        "fontFamily": "Avenir Regular",
                                        "color": "#646464",
                                        "marginTop": "20px"
                                    }}>
                                        <CardHeader titleTypographyProps={{ "variant": "table-title" }} title="Comunidades" />
                                        <CardContent>
                                            {(userComunidadesActivity && groups) ?
                                                <Grid container columnSpacing={2} display={"flex"} justifyContent={"space-between"}>
                                                    <Grid item xs={12} sm={6}>
                                                        <Typography ml={"10px"}><b>Posts publicados: </b>{userComunidadesActivity.filter(e => e.eventType === "Creó una discusión").length}</Typography>
                                                        <Typography ml={"10px"}><b>Likes emitidos: </b>{userComunidadesActivity.filter(e => e.eventType === "Emitió un like").length}</Typography>
                                                        <Typography ml={"10px"}><b>Tiempo en discusiones: </b>{(selectedUser.discourse_data.time_read / 60).toFixed(1)} min</Typography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <Typography ml={"10px"}><b>Grupos: </b>{groups.filter(g => g.users.includes(selectedUser.username)).length}</Typography>
                                                        <Typography ml={"10px"}><b>Likes recibidos: </b>{userComunidadesActivity.filter(e => e.eventType === "Recibió un like").length}</Typography>
                                                        <Typography ml={"10px"}><b>Discusiones leídas: </b>{selectedUser.discourse_data.topics_entered}</Typography>
                                                    </Grid>
                                                </Grid>
                                                :
                                                <Box textAlign={"center"}><CircularProgress sx={{ "color": "#40b4ba" }}></CircularProgress></Box>
                                            }
                                        </CardContent>
                                    </Card>
                                    <Card sx={{
                                        "fontFamily": "Avenir Regular",
                                        "color": "#646464",
                                        "marginTop": "20px"
                                    }}>
                                        <CardHeader titleTypographyProps={{ "variant": "table-title" }} title="Recursos" />
                                        <CardContent>
                                            {(recursos && recursosDocentes && activities) ?
                                                <Grid container columnSpacing={2} display={"flex"} justifyContent={"space-between"}>
                                                    <Grid item xs={12} sm={6}>
                                                        <Typography color={recursos ? "#646464" : "red"} ml={"10px"}>
                                                            <b>Recursos aprobados: </b>
                                                            {recursos.filter(r => (r.username === selectedUser.username && r.status === "approved")).length + activities.filter(a => a.username === selectedUser.username && a.status === "approved").length}
                                                        </Typography>
                                                        <Typography ml={"10px"}>
                                                            <b>Recursos visitados: </b>
                                                            {recursos.reduce((acc, r) => acc + r.visits.filter(v => v.username === selectedUser.username).length, 0) +
                                                                activities.reduce((acc, a) => acc + a.visits.filter(v => v.username === selectedUser.username).length, 0)}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <Typography ml={"10px"}>
                                                            <b>Recursos descargados: </b>
                                                            {recursos.reduce((acc, r) => acc + r.downloads_accesses.filter(v => v.username === selectedUser.username).length, 0) +
                                                                recursosDocentes.reduce((acc, rd) => acc + rd.downloads_accesses.filter(v => v.username === selectedUser.username).length, 0)}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                                :
                                                <Box textAlign={"center"}><CircularProgress sx={{ "color": "#40b4ba" }}></CircularProgress></Box>
                                            }
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        }
                    ></Modal>
                    <Modal
                        open={activityModal}
                        handleClose={handleCloseActivityModal}
                        title="Actividad de usuario"
                        onClickMainButton={handleCloseActivityModal}
                        mainButtonText="OK"
                        hideMainButton={true}
                        width="md"
                        cancelButton={false}
                        content={
                            loadingUserActivity ?
                                <Box textAlign="center"><CircularProgress sx={{ color: "#40b4ba" }}></CircularProgress></Box>
                                :
                                <>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Typography variant="p">
                                                Aquí puede conocer la actividad de <b>{selectedUser.firstName + " " + selectedUser.lastName} ({selectedUser.username})</b> en los ambientes de RedFID.
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="h6">Acciones por semana</Typography>
                                            <LineChart
                                                id={"new-users-by-day"}
                                                height={"300px"}
                                                timeInterval={"week"}
                                                tooltipFormatter={(value) => value + " acciones"}
                                                data={() => {
                                                    let out = [];
                                                    for (let ev of userKeycloakActivity.concat(userComunidadesActivity)) {
                                                        let date = new Date(ev.time).toISOString().slice(0, 10).replace(/-/g, "");
                                                        let day = out.find(day => day.date === date);
                                                        if (day) {
                                                            day.value++;
                                                        } else {
                                                            out.push({
                                                                "date": date,
                                                                "value": 1,
                                                                "color": "#eb947e"
                                                            });
                                                        }
                                                    }
                                                    out = out.sort((a, b) => a.date - b.date);
                                                    return out;
                                                }}
                                            ></LineChart>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <UserActivityTable
                                                keycloakEvents={userKeycloakActivity}
                                                comunidadesEvents={userComunidadesActivity}
                                                recursosEvents={userRecursosActivity}
                                                loadingEvents={loadingUserActivity}
                                                handleOpenActivityCsvModal={handleOpenActivityCsvModal}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Modal
                                        open={activityCsvModal}
                                        handleClose={handleCloseActivityCsvModal}
                                        title="Descargar actividad"
                                        onClickMainButton={handleDownloadActivityCSV}
                                        mainButtonText="Descargar"
                                        mainButtonDisabled={formLoading}
                                        width="md"
                                        cancelButton={true}
                                        startIcon={<DownloadIcon />}
                                        content={
                                            <>
                                                <Typography variant="p" textAlign={"justify"} mb={"20px"}>
                                                    Se descargará un archivo en formato .csv con la actividad de <b>{selectedUser.firstName + " " + selectedUser.lastName} ({selectedUser.username})</b> en RedFID.
                                                    Por favor, seleccione los campos que desea incluir:
                                                </Typography>
                                                <DownloadCSVActivityForm
                                                    csvEventPlatform={csvEventPlatform}
                                                    setCsvEventPlatform={setCsvEventPlatform}
                                                    csvEventType={csvEventType}
                                                    setCsvEventType={setCsvEventType}
                                                    csvExcel={csvEventExcel}
                                                    setCsvExcel={setCsvEventExcel}
                                                />
                                            </>
                                        }
                                    >
                                    </Modal>
                                </>
                        }
                    ></Modal>
                    <Modal
                        open={changePasswordModal}
                        handleClose={handleCloseChangePasswordModal}
                        title="Cambiar contraseña"
                        onClickMainButton={handleChangePassword}
                        mainButtonText="Cambiar"
                        mainButtonDisabled={formLoading}
                        width="md"
                        cancelButton={true}
                        startIcon={<VpnKeyIcon />}
                        content={
                            <>
                                <Typography variant="p" textAlign={"justify"} mb={"20px"}>
                                    Por favor, introduzca la nueva contraseña para el usuario <b>{selectedUser.firstName + " " + selectedUser.lastName} ({selectedUser.username})</b>:
                                </Typography>
                                <TextField
                                    label="Nueva contraseña"
                                    variant="outlined"
                                    fullWidth
                                    value={newPassword}
                                    onChange={(e) => {
                                        setPasswordErrors({});
                                        setNewPassword(e.target.value)
                                    }}
                                    error={passwordErrors.newPassword ? true : false}
                                    helperText={passwordErrors.newPassword}
                                    disabled={formLoading}
                                />
                            </>
                        }
                    ></Modal>
                    <Modal
                        open={editModal}
                        handleClose={handleCloseEditModal}
                        title="Editar usuario"
                        onClickMainButton={handleEditUser}
                        mainButtonText="Editar"
                        mainButtonDisabled={formLoading}
                        width="lg"
                        cancelButton={true}
                        startIcon={<EditIcon />}
                        content={
                            <>
                                <Grid item xs={12}>
                                    <Typography variant="p">
                                        Aquí puede editar la información de usuario de <b>{selectedUser.firstName + " " + selectedUser.lastName} ({selectedUser.username})</b>, así como sus
                                        atributos y roles en RedFID.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <UserForm
                                        username={selectedUser.username}
                                        email={editEmail}
                                        setEmail={setEditEmail}
                                        firstName={editFirstName}
                                        setFirstName={setEditFirstName}
                                        lastName={editLastName}
                                        setLastName={setEditLastName}
                                        gender={editGender}
                                        setGender={setEditGender}
                                        career={editCareer}
                                        setCareer={setEditCareer}
                                        region={editRegion}
                                        setRegion={setEditRegion}
                                        active={editActive}
                                        setActive={setEditActive}
                                        isFormador={editIsFormador}
                                        setIsFormador={setEditIsFormador}
                                        isStaff={editIsStaff}
                                        setIsStaff={setEditIsStaff}
                                        isAdmin={editIsAdmin}
                                        setIsAdmin={setEditIsAdmin}
                                        alternateEmail={editAlternateEmail}
                                        setAlternateEmail={setEditAlternateEmail}
                                        university={editUniversity}
                                        setUniversity={setEditUniversity}
                                        setUniversityCode={setEditUniversityCode}
                                        setUniversityName={setEditUniversityName}
                                        formLoading={formLoading}
                                        errors={editErrors}
                                        setErrors={setEditErrors}
                                        attributes={attributes}
                                        editing={true}
                                    />
                                </Grid>
                            </>

                        }
                    ></Modal>
                    <Modal
                        open={suspendModal}
                        handleClose={handleCloseSuspendModal}
                        title="Suspender usuario"
                        onClickMainButton={handleSuspendUser}
                        mainButtonText={selectedUser.enabled ? "Suspender" : "Activar"}
                        mainButtonDisabled={formLoading}
                        width="md"
                        cancelButton={true}
                        startIcon={selectedUser.enabled ? <BlockIcon /> : <CheckCircleOutline />}
                        content={
                            <>
                                <Typography variant="p" textAlign={"justify"} mb={"20px"}>
                                    ¿Está segur@ que desea {selectedUser.enabled ? "suspender" : "activar"} al usuario seleccionado?
                                </Typography>
                                <Typography variant="p" textAlign={"center"} mb={"20px"}>
                                    <b>{selectedUser.firstName + " " + selectedUser.lastName} ({selectedUser.username})</b>
                                </Typography>
                                <Typography variant="p" textAlign={"justify"} mb={"20px"}>
                                    {selectedUser.enabled ? "El usuario no podrá acceder a ningún ambiente de la plataforma." : "El usuario podrá acceder a todos los ambientes de la plataforma."} Esta acción es reversible.
                                </Typography>
                            </>
                        }
                    ></Modal>
                    <Modal
                        open={deleteModal}
                        handleClose={handleCloseDeleteModal}
                        title="Eliminar usuario"
                        onClickMainButton={handleDeleteUser}
                        mainButtonText="Eliminar"
                        mainButtonDisabled={formLoading}
                        width="md"
                        cancelButton={true}
                        startIcon={<DeleteForeverIcon />}
                        content={
                            <>
                                <Typography variant="p" textAlign={"justify"} mb={"20px"}>
                                    ¿Está segur@ que desea eliminar al usuario seleccionado?
                                </Typography>
                                <Typography variant="p" textAlign={"center"} mb={"20px"}>
                                    <b>{selectedUser.firstName + " " + selectedUser.lastName} ({selectedUser.username})</b>
                                </Typography>
                                <Typography variant="p" textAlign={"justify"} mb={"20px"}>
                                    Esta acción no se puede deshacer y eliminará todos los datos asociados al usuario.
                                </Typography>
                            </>
                        }
                    ></Modal>
                </>
            }
            <Modal
                open={downloadUniversitiesCSVModal}
                handleClose={handleCloseDownloadUniversitiesCSVModal}
                title="Descargar datos"
                onClickMainButton={handleDownloadUniversitiesCSV}
                mainButtonText="Descargar"
                mainButtonDisabled={formLoading}
                width="lg"
                cancelButton={true}
                startIcon={<DownloadIcon />}
                content={
                    <>
                        <Typography variant="p" mb={"20px"}>
                            Se descargará un archivo en formato .csv con la información de todas las universidades con usuarios inscritos en RedFID.
                            Por favor, seleccione los campos que desea incluir:
                        </Typography>
                        <DownloadCSVUniversitiesForm
                            csvUniversityName={csvUniversityName}
                            setCsvUniversityName={setCsvUniversityName}
                            csvUniversityMembers={csvUniversityMembers}
                            setCsvUniversityMembers={setCsvUniversityMembers}
                            csvUniversityMembersList={csvUniversityMembersList}
                            setCsvUniversityMembersList={setCsvUniversityMembersList}
                            csvExcel={csvUniversityExcel}
                            setCsvExcel={setCsvUniversityExcel}
                        />
                    </>
                }
            ></Modal>
            {selectedUniversity &&
                <Modal
                    open={logoModal}
                    handleClose={handleCloseLogoModal}
                    title="Logo de universidad"
                    onClickMainButton={handleCloseLogoModal}
                    mainButtonText="Subir"
                    mainButtonDisabled={formLoading}
                    width="md"
                    cancelButton={true}
                    content={
                        <>
                            <Typography variant="p" textAlign={"justify"} mb={"20px"}>
                                Por favor, seleccione el archivo de imagen que desea subir como logo de la universidad:
                            </Typography>
                        </>
                    }
                ></Modal>
            }
            {selectedUniversity &&
                <Modal
                    open={membersModal}
                    handleClose={handleCloseMembersModal}
                    title={"Miembros de " + selectedUniversity.universityName}
                    onClickMainButton={handleCloseMembersModal}
                    mainButtonText="OK"
                    hideMainButton={true}
                    width="md"
                    cancelButton={false}
                    content={
                        <>
                            <UniversityMembersTable
                                users={users.filter(user => user.attributes && user.attributes.university && user.attributes.university[0] === selectedUniversity.universityCode)}
                            ></UniversityMembersTable>
                        </>
                    }
                ></Modal>
            }
        </>
    )
}

export default Usuarios;