import React from 'react';
import { Grid, Box, Button, IconButton, TextField, MenuItem, Chip, Autocomplete, CircularProgress, Typography } from '@mui/material';
import { WithContext as ReactTags } from 'react-tag-custom';
import { formatUsersIntoSelector, formatFileSize } from '../../../utils/formatters';
import FileUpload from '../../../utils/fileUpload';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloseIcon from '@mui/icons-material/Close';


const SendEmailForm = ({
    emailFrom,
    setEmailFrom,
    emailTo,
    setEmailTo,
    usersTo,
    setUsersTo,
    careerTo,
    setCareerTo,
    regionTo,
    setRegionTo,
    eventTo,
    setEventTo,
    universityTo,
    setUniversityTo,
    groupTo,
    setGroupTo,
    courseTo,
    setCourseTo,
    courseSectionTo,
    setCourseSectionTo,
    customEmailsTo,
    setCustomEmailsTo,
    template,
    setTemplate,
    subject,
    setSubject,
    message,
    setMessage,
    attachment,
    setAttachment,
    formLoading,
    errors,
    setErrors,
    users,
    attributes,
    groups,
    courses,
    events
}) => {

    const handleAttachmentChange = (event) => {
        setAttachment(event.target.files[0]);
        setErrors(prevErrors => ({ ...prevErrors, attachment: '' }));
    }

    const resetAttachment = () => {
        setAttachment(null);
        setErrors(prevErrors => ({ ...prevErrors, attachment: '' }));
    }

    return (
        (users && groups && courses && events) ?
            <>
                <Grid container columnSpacing={2} alignItems={"center"}>
                    <Grid item xs={12} md={6} mt={"20px"}>
                        <TextField
                            fullWidth
                            label="Asunto"
                            variant="outlined"
                            value={subject}
                            onChange={(e) => {
                                setSubject(e.target.value)
                                setErrors(prevErrors => ({ ...prevErrors, subject: "" }))
                            }}
                            error={!!errors.subject}
                            helperText={errors.subject}
                            disabled={formLoading}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} mt={"20px"}>
                        <TextField
                            fullWidth
                            select
                            label="Plantilla"
                            variant="outlined"
                            value={template}
                            onChange={(e) => {
                                setTemplate(e.target.value)
                                setErrors(prevErrors => ({ ...prevErrors, template: "" }))
                            }}
                            error={!!errors.template}
                            helperText={errors.template}
                            disabled={formLoading}
                        >
                            <MenuItem value="none" disabled>Por favor seleccione una opción...</MenuItem>
                            <MenuItem value="announcement">Anuncio</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} mt={"20px"}>
                        <TextField
                            fullWidth
                            select
                            label="Desde"
                            variant="outlined"
                            value={emailFrom}
                            onChange={(e) => {
                                setEmailFrom(e.target.value)
                                setErrors(prevErrors => ({ ...prevErrors, emailFrom: "" }))
                            }}
                            error={!!errors.emailFrom}
                            helperText={errors.emailFrom}
                            disabled={formLoading}
                        >
                            <MenuItem value="none" disabled>Por favor seleccione una opción...</MenuItem>
                            <MenuItem value="admin@redfid.cl">RedFID — Administración (admin@redfid.cl)</MenuItem>
                            <MenuItem value="contacto@redfid.cl">RedFID — Contacto (contacto@redfid.cl)</MenuItem>
                            <MenuItem value="difusion@redfid.cl">RedFID — Difusión (difusion@redfid.cl)</MenuItem>
                            <MenuItem value="comunidad@redfid.cl">RedFID — Comunidad (comunidad@redfid.cl)</MenuItem>
                            <MenuItem value="postulacionfondos@redfid.cl">RedFID — Postulación Fondos (postulacionfondos@redfid.cl)</MenuItem>
                            <MenuItem value="comiteejecutivo@redfid.cl">RedFID — Comité Ejecutivo (comiteejecutivo@redfid.cl)</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item xs={12} md={4} mt={"20px"}>
                        <TextField
                            fullWidth
                            label="Destinatarios"
                            select
                            variant="outlined"
                            value={emailTo}
                            onChange={(e) => {
                                setEmailTo(e.target.value)
                                setErrors(prevErrors => ({ ...prevErrors, emailTo: "" }))
                            }}
                            error={!!errors.emailTo}
                            helperText={errors.emailTo ? errors.emailTo : "Sólo se consideran usuarios activos."}
                            disabled={formLoading}
                        >
                            <MenuItem value="none" disabled>Por favor seleccione una opción...</MenuItem>
                            <MenuItem value="all">Todos los usuarios</MenuItem>
                            <MenuItem value="staff">Todo el staff</MenuItem>
                            <MenuItem value="formadores">Todos los formadores</MenuItem>
                            <MenuItem value="carrera">Formadores de una carrera</MenuItem>
                            <MenuItem value="region">Formadores de una región</MenuItem>
                            <MenuItem value="universidad">Formadores de una universidad</MenuItem>
                            <MenuItem value="asistieron">Formadores que asistieron a un evento</MenuItem>
                            <MenuItem value="no_asistieron">Formadores que no asistieron a un evento</MenuItem>
                            <MenuItem value="inscritos">Formadores inscritos en un curso</MenuItem>
                            <MenuItem value="no_inscritos">Formadores que no se han inscrito a un curso</MenuItem>
                            <MenuItem value="terminaron">Formadores que terminaron un curso</MenuItem>
                            <MenuItem value="no_terminaron">Formadores que no han terminado un curso</MenuItem>
                            <MenuItem value="group">Miembros de un grupo en Discourse</MenuItem>
                            <MenuItem value="users">Seleccionar usuario(s)</MenuItem>
                            <MenuItem value="other">Otro(s)</MenuItem>
                        </TextField>
                    </Grid>
                    {(emailTo === 'inscritos' || emailTo === 'no_inscritos' || emailTo === 'terminaron' || emailTo === 'no_terminaron') && (
                        <>
                            <Grid item xs={12} md={4} mt={"20px"}>
                                <TextField
                                    fullWidth
                                    select
                                    label="Curso"
                                    variant="outlined"
                                    value={courseTo}
                                    onChange={(e) => {
                                        setCourseTo(e.target.value)
                                        setErrors(prevErrors => ({ ...prevErrors, courseTo: "" }))
                                    }}
                                    error={!!errors.courseTo}
                                    helperText={errors.courseTo}
                                    disabled={formLoading}
                                >
                                    <MenuItem value="none" disabled>Por favor seleccione un curso...</MenuItem>
                                    {Object.keys(courses).map(course => (
                                        <MenuItem key={course} value={course}>{courses[course].name}</MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            {(emailTo !== "none" && courseTo !== "none" && emailTo !== "no_inscritos") && (
                                <Grid item xs={12} md={4} mt={"20px"}>
                                    <TextField
                                        fullWidth
                                        select
                                        label="Versión"
                                        variant="outlined"
                                        value={courseSectionTo}
                                        onChange={(e) => {
                                            setCourseSectionTo(e.target.value)
                                            setErrors(prevErrors => ({ ...prevErrors, courseSectionTo: "" }))
                                        }}
                                        error={!!errors.courseSectionTo}
                                        helperText={errors.courseSectionTo}
                                        disabled={formLoading}
                                    >
                                        <MenuItem value="none" disabled>Por favor seleccione una versión...</MenuItem>
                                        <MenuItem value="all">Todas las versiones</MenuItem>
                                        {Object.keys(courses[courseTo]["sections"]).filter(section => section !== "0").map(section => (
                                            <MenuItem key={section} value={section}>Versión N°{section} ({courses[courseTo]["sections"][section]["course_id"].split("+")[2]})</MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                            )}
                        </>
                    )}
                    {emailTo === 'group' && (
                        <Grid item xs={12} md={4} mt={"20px"}>
                            <TextField
                                fullWidth
                                select
                                label="Grupo"
                                variant="outlined"
                                value={groupTo}
                                onChange={(e) => {
                                    setGroupTo(e.target.value)
                                    setErrors(prevErrors => ({ ...prevErrors, groupTo: "" }))
                                }}
                                error={!!errors.groupTo}
                                helperText={errors.groupTo}
                                disabled={formLoading}
                            >
                                <MenuItem value="none" disabled>Por favor seleccione un grupo...</MenuItem>
                                {groups.map(group => (
                                    <MenuItem key={group.id} value={group.id}>{group.full_name}</MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                    )}
                    {(emailTo === 'asistieron' || emailTo === 'no_asistieron') && (
                        <Grid item xs={12} md={8} mt={"20px"}>
                            <TextField
                                fullWidth
                                select
                                label="Evento"
                                variant="outlined"
                                value={eventTo}
                                onChange={(e) => {
                                    setEventTo(e.target.value)
                                    setErrors(prevErrors => ({ ...prevErrors, eventTo: "" }))
                                }}
                                error={!!errors.eventTo}
                                helperText={errors.eventTo}
                                disabled={formLoading}
                            >
                                <MenuItem value="none" disabled>Por favor seleccione un evento...</MenuItem>
                                {events.map(event => (
                                    <MenuItem key={event.id} value={event.id}>{event.title}</MenuItem>
                                ))}
                            </TextField>
                        </Grid>

                    )}
                    {emailTo === 'carrera' && (
                        <Grid item xs={12} md={8} mt={"20px"}>
                            <TextField
                                fullWidth
                                select
                                label="Carrera"
                                variant="outlined"
                                value={careerTo}
                                onChange={(e) => {
                                    setCareerTo(e.target.value)
                                    setErrors(prevErrors => ({ ...prevErrors, careerTo: "" }))
                                }}
                                error={!!errors.careerTo}
                                helperText={errors.careerTo}
                                disabled={formLoading}
                            >
                                <MenuItem value="none" disabled>Por favor seleccione una carrera...</MenuItem>
                                {Object.keys(attributes.career.options).map(key => (
                                    <MenuItem key={key} value={key}>{attributes.career.options[key]}</MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                    )}
                    {emailTo === 'region' && (
                        <Grid item xs={12} md={8} mt={"20px"}>
                            <TextField
                                fullWidth
                                select
                                label="Región"
                                variant="outlined"
                                value={regionTo}
                                onChange={(e) => {
                                    setRegionTo(e.target.value)
                                    setErrors(prevErrors => ({ ...prevErrors, regionTo: "" }))
                                }}
                                error={!!errors.regionTo}
                                helperText={errors.regionTo}
                                disabled={formLoading}
                            >
                                <MenuItem value="none" disabled>Por favor seleccione una región...</MenuItem>
                                {Object.keys(attributes.region.options).map(key => (
                                    <MenuItem key={key} value={key}>{attributes.region.options[key]}</MenuItem>
                                )).filter((item) => users.filter((user) => user.attributes && user.attributes.region && user.attributes.region[0] === item.key).length > 0)}
                            </TextField>
                        </Grid>
                    )}
                    {emailTo === 'universidad' && (
                        <Grid item xs={12} md={8} mt={"20px"}>
                            <TextField
                                fullWidth
                                select
                                label="Universidad"
                                variant="outlined"
                                value={universityTo}
                                onChange={(e) => {
                                    setUniversityTo(e.target.value)
                                    setErrors(prevErrors => ({ ...prevErrors, universityTo: "" }))
                                }}
                                error={!!errors.universityTo}
                                helperText={errors.universityTo}
                                disabled={formLoading}
                            >
                                <MenuItem value="none" disabled>Por favor seleccione una universidad...</MenuItem>
                                {Object.keys(attributes.university.options).map(key => (
                                    <MenuItem key={key} value={key}>{attributes.university.options[key]}</MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                    )}
                    {emailTo === 'users' && (
                        <Grid item xs={12} md={8} mt={"20px"}>
                            <Autocomplete
                                multiple
                                value={usersTo}
                                onChange={(event, newValue) => {
                                    setUsersTo(newValue.map((user) => { return { email: user.email, label: user.label, username: user.username, firstName: user.firstName, lastName: user.lastName } }));
                                    setErrors({ ...errors, usersTo: "" });

                                }}
                                id="tags-outlined"
                                options={formatUsersIntoSelector(users)}
                                getOptionLabel={(user) => user.label + " (" + user.username + ")"}
                                isOptionEqualToValue={(option, value) => { return option.username === value.username }}
                                getOptionKey={(user) => user.email}
                                defaultValue={undefined}
                                filterSelectedOptions
                                noOptionsText="No se encontraron usuarios."
                                renderOption={(props, option) => {
                                    return (
                                        <li {...props} key={option.username}>
                                            {option.label}
                                        </li>
                                    )
                                }}
                                renderTags={(tagValue, getTagProps) => {
                                    return tagValue.map((option, index) => (
                                        <Chip variant="orange" {...getTagProps({ index })} key={option.username} label={option.label} />
                                    ))
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Usuarios"
                                        placeholder=""
                                    />
                                )}
                            />
                            {errors.usersTo && <Typography variant="caption" color="#d32f2f">{errors.usersTo}</Typography>}
                        </Grid>
                    )}
                    {emailTo === 'other' && (
                        <Grid item xs={12} md={8} mt={"20px"}>
                            <ReactTags
                                tags={customEmailsTo}
                                suggestions={[]}
                                handleDelete={(i) => {
                                    const newEmails = customEmailsTo.slice(0);
                                    newEmails.splice(i, 1);
                                    setCustomEmailsTo(newEmails);
                                }}
                                handleAddition={(email) => {
                                    setCustomEmailsTo([...customEmailsTo, email]);
                                }}
                                delimiters={[188, 13]}
                                placeholder="Agregar email.."
                            ></ReactTags>
                            {errors.customEmailsTo && <Typography variant="caption" color="#d32f2f">{errors.customEmailsTo}</Typography>}
                        </Grid>
                    )}
                    <Grid item xs={12} mt={"20px"}>
                        <TextField
                            fullWidth
                            label="Mensaje"
                            multiline
                            rows={8}
                            variant="outlined"
                            value={message}
                            onChange={(e) => {
                                setMessage(e.target.value)
                                setErrors(prevErrors => ({ ...prevErrors, message: "" }))
                            }}
                            error={!!errors.message}
                            helperText={
                                errors.message ?
                                    <span style={{ color: '#d32f2f', fontSize: '0.75rem', textAlign: "center" }}>{errors.message}</span>
                                    :
                                    <span style={{ color: '#646464', fontSize: '0.75rem', textAlign: "center" }}>
                                        Se soporta HTML. Puedes dejar texto en negrita envolviéndolo <code>&lt;b&gt;</code><b>así</b><code>&lt;/b&gt;</code>, y texto en cursiva envolviéndolo <code>&lt;i&gt;</code><i>así</i><code>&lt;/i&gt;</code>.
                                        También puedes agregar enlaces con <code>&lt;a href="URL"&gt;</code>link<code>&lt;/a&gt;</code>, entre otras funcionalidades.
                                    </span>
                                
                            }
                            disabled={formLoading}
                        />
                    </Grid>
                    <Grid item xs={12} textAlign={"center"} mt={"40px"}>
                        <Grid>
                            <Button
                                component="label"
                                role={undefined}
                                variant="orange"
                                tabIndex={-1}
                                onChange={handleAttachmentChange}
                                startIcon={<CloudUploadIcon />}
                                disabled={formLoading}
                            >
                                Archivo adjunto
                                <FileUpload type="file" onChange={handleAttachmentChange} accept={".png,.jpg,.jpeg,.gif,.txt,.doc,.docx,.xls,.xlsx,.pdf"} />
                            </Button>
                            <Typography ml={"8px"} sx={{ "color": "#646464", "fontFamily": "Avenir Regular" }} variant="caption">
                                {attachment && (
                                    <Box sx={{ display: 'flex', alignItems: 'center', mt: 1, justifyContent: 'center' }}>
                                        <Typography sx={{ "color": "#646464", "fontFamily": "Avenir Regular" }} variant="caption">
                                            {attachment ? `${attachment.name} (${formatFileSize(attachment.size)})` : "Por favor seleccione un archivo..."}
                                        </Typography>
                                        <IconButton disabled={formLoading} sx={{ marginLeft: "10px" }} size="small" onClick={resetAttachment}>
                                            <CloseIcon fontSize="inherit" />
                                        </IconButton>
                                    </Box>
                                )}
                            </Typography>
                            {errors.attachment ? <Typography sx={{ marginTop: "10px", fontSize: "0.75rem" }} width={"100%"} variant="p" color="error">{errors.attachment}</Typography> : <></>}
                        </Grid>
                    </Grid>
                </Grid>
            </>
            :
            <Box textAlign="center" mt={2}>
                <CircularProgress sx={{ color: "#40b4ba" }}></CircularProgress>
            </Box>
    );

}

export default SendEmailForm;