import React, { useState } from 'react';
import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid';
import { Typography, IconButton, Box, Menu, MenuItem, Avatar } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DownloadIcon from '@mui/icons-material/Download';
import { esES } from '@mui/x-data-grid/locales';


const UniversitiesTable = ({
    users,
    attributes,
    handleOpenLogoModal,
    handleOpenMembersModal,
    handleOpenDownloadCSVModal
}) => {

    const [paginationModel, setPaginationModel] = useState({ pageSize: 10, page: 0 });

    const columns = [
        {
            field: 'actions',
            headerName: 'Acciones',
            sortable: false,
            width: 100,
            disableColumnMenu: true,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
                <ActionsMenu user={params.row} />
            )
        },
        {
            field: 'picture',
            headerName: 'Logo',
            sortable: false,
            width: 60,
            disableColumnMenu: true,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
                <Avatar src={process.env.REACT_APP_API_URL + "/get_logo/" + params.row.universityCode + "/" + "1"} alt="Logo" style={{ marginTop: "5px", width: 40, height: 40, borderRadius: 0 }} />
            )
        },
        { 
            field: 'universityCode', 
            disableColumnMenu: true, 
            headerName: 'Código', 
            width: 120, 
            align: 'left', 
            headerAlign: 'left' 
        },
        { 
            field: 'universityName', 
            disableColumnMenu: true, 
            headerName: 'Nombre', 
            flex: 1, 
            align: 'left', 
            headerAlign: 'left' 
        },
        { 
            field: 'members', 
            disableColumnMenu: true, 
            headerName: 'Miembros', 
            width: 120, 
            align: 'center', 
            headerAlign: 'center', 
            valueGetter: (params, row) => row.members.length 
        },
        { 
            field: 'activeMembers', 
            disableColumnMenu: true, 
            headerName: 'Miembros activos', 
            width: 180, 
            align: 'center', 
            headerAlign: 'center', 
            valueGetter: (params, row) => row.activeMembers.length 
        },
    ];

    const ActionsMenu = ({ user }) => {
        const [anchorEl, setAnchorEl] = useState(null);

        const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
        };

        const handleClose = () => {
            setAnchorEl(null);
        };

        return (
            <div>
                <IconButton
                    aria-controls="actions-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                >
                    <MoreVertIcon />
                </IconButton>
                <Menu
                    id="actions-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem
                        onClick={() => {
                            handleOpenMembersModal(user);
                        }}
                    >
                        <InfoIcon sx={{ "marginRight": "10px" }} /> Miembros
                    </MenuItem>
                </Menu>
            </div>
        );
    };

    function CustomToolbar() {
        return (
            <GridToolbarContainer sx={{ "display": "flex", "justifyContent": "space-between", "padding": "15px 10px" }}>
                <Typography variant='table-title'>Universidades</Typography>
                <Box>
                    <IconButton onClick={handleOpenDownloadCSVModal}><DownloadIcon></DownloadIcon></IconButton>
                </Box>
            </GridToolbarContainer>
        );
    }

    return (
        <div style={{ width: '100%', margin: "40px 0" }}>
            <DataGrid
                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                rows={Object.keys(attributes.university.options).map((code) => {
                    return {
                        id: code,
                        universityCode: code,
                        universityName: attributes.university.options[code],
                        members: users.filter((user) => (user.attributes && user.attributes.is_formador && user.attributes.is_formador[0] === "1" && user.attributes.university && user.attributes.university[0] === code)),
                        activeMembers: users.filter((user) => (user.attributes && user.attributes.is_formador && user.attributes.is_formador[0] === "1" && user.attributes.university && user.attributes.university[0] === code && user.enabled)),
                    }
                }).filter((university) => university.members.length > 0)}
                columns={columns}
                pageSizeOptions={[10, 25, 50]}
                onPaginationModelChange={setPaginationModel}
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'activeMembers', sort: 'desc' }],
                    },
                    pagination: {
                        paginationModel
                    }
                }}
                slots={{
                    toolbar: CustomToolbar,
                }}
            />
        </div>
    );

}

export default UniversitiesTable;