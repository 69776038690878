import React, { useState, useEffect } from 'react';
import YouTube, { YouTubeProps } from 'react-youtube';
import { Box, CircularProgress, Grid, IconButton, Card, CardContent, TextField, Link, MenuItem, Button, Typography, CardHeader } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import UploadIcon from '@mui/icons-material/Upload';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import BlockIcon from '@mui/icons-material/Block';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import Indicator from './components/Indicator';
import Modal from './components/Modal';
import CapsulasTable from './components/tables/CapsulasTable';
import FileUpload from '../utils/fileUpload';
import CapsulaForm from './components/forms/CapsulaForm';
import DownloadCSVCapsulasForm from './components/forms/DownloadCSVCapsulasForm';
import { capsulasCSV } from '../utils/csvDownload';
import { translateCapsulaKind, colorsCapsulaKind, formatUsersIntoSelector } from '../utils/formatters';
import { downloadCapsulaTemplate } from '../requests/capsulas/downloadCapsulaTemplate';
import { createCapsula } from '../requests/capsulas/createCapsula';
import { editCapsula } from '../requests/capsulas/editCapsula';
import { toggleCapsulaVisibility } from '../requests/capsulas/toggleCapsulaVisibility';
import { deleteCapsula } from '../requests/capsulas/deleteCapsula';
import { updateCapsulaTemplate } from '../requests/capsulas/updateCapsulaTemplate';
import { validateCapsulasForm } from '../utils/validators';
import { api } from '../API';
import BarChart from './components/charts/BarChart';
import PieChart from './components/charts/PieChart';
import { getCapsulaChip } from '../utils/chips';


const Capsulas = ({
    setAlertSuccess,
    setAlertWarning,
    setAlertError,
    alerts,
    setAlerts,
    attributes,
    capsulas,
    setCapsulas,
    users
}) => {

    const [selectedCapsula, setSelectedCapsula] = useState(null);
    const [downloadCSVModal, setDownloadCSVModal] = useState(false);
    const [statsModal, setStatsModal] = useState(false);
    const [createModal, setCreateModal] = useState(false);
    const [infoModal, setInfoModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [suspendModal, setSuspendModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [formLoading, setFormLoading] = useState(false);

    const [title, setTitle] = useState("");
    const [kind, setKind] = useState("none");
    const [date, setDate] = useState("");
    const [video_url, setVideoUrl] = useState("");
    const [exposes, setExposes] = useState("");
    const [exposesSubtitle, setExposesSubtitle] = useState("");
    const [description, setDescription] = useState("");
    const [priority, setPriority] = useState("");
    const [published, setPublished] = useState("none");
    const [user, setUser] = useState(null);
    const [errors, setErrors] = useState({});

    const [editTitle, setEditTitle] = useState("");
    const [editKind, setEditKind] = useState("none");
    const [editDate, setEditDate] = useState("");
    const [editVideoUrl, setEditVideoUrl] = useState("");
    const [editExposes, setEditExposes] = useState("");
    const [editExposesSubtitle, setEditExposesSubtitle] = useState("");
    const [editDescription, setEditDescription] = useState("");
    const [editPriority, setEditPriority] = useState("");
    const [editPublished, setEditPublished] = useState("none");
    const [editUser, setEditUser] = useState(null);
    const [editErrors, setEditErrors] = useState({});

    const [csvTitle, setCsvTitle] = useState(true);
    const [csvDescription, setCsvDescription] = useState(true);
    const [csvExposes, setCsvExposes] = useState(true);
    const [csvDate, setCsvDate] = useState(true);
    const [csvKind, setCsvKind] = useState(true);
    const [csvPriority, setCsvPriority] = useState(true);
    const [csvPublished, setCsvPublished] = useState(true);
    const [csvVideoUrl, setCsvVideoUrl] = useState(true);
    const [csvUrl, setCsvUrl] = useState(true);
    const [csvViewCount, setCsvViewCount] = useState(true);
    const [csvLikeCount, setCsvLikeCount] = useState(true);
    const [csvCommentCount, setCsvCommentCount] = useState(true);
    const [csvFavoriteCount, setCsvFavoriteCount] = useState(true);
    const [csvUser, setCsvUser] = useState(true);
    const [csvExcel, setCsvExcel] = useState(true);

    const [templateType, setTemplateType] = useState("none");
    const [templateUniversity, setTemplateUniversity] = useState("none");
    const [templateFeedback, setTemplateFeedback] = useState(null);
    const [templateNewFile, setTemplateNewFile] = useState(null);

    const onPlayerReady = (event) => {
        event.target.pauseVideo();
    }

    const opts = {
        height: '390',
        width: '640',
        playerVars: {
            autoplay: 1,
        },
    };

    const handleOpenCreateModal = () => {
        setCreateModal(true);
    }

    const handleCloseCreateModal = () => {
        setErrors({});
        setCreateModal(false);
    }

    const handleOpenStatsModal = () => {
        setStatsModal(true);
    }

    const handleCloseStatsModal = () => {
        setStatsModal(false);
    }

    const handleOpenDownloadCSVModal = () => {
        setDownloadCSVModal(true);
    }

    const handleCloseDownloadCSVModal = () => {
        setDownloadCSVModal(false);
    }

    const handleOpenInfoModal = (capsula) => {
        setSelectedCapsula(capsula);
        setInfoModal(true);
    };

    const handleCloseInfoModal = () => {
        setSelectedCapsula(null);
        setInfoModal(false);
    };

    const handleOpenEditModal = (capsula) => {
        setSelectedCapsula(capsula);
        setEditTitle(capsula.title);
        setEditKind(capsula.kind);
        setEditDate(capsula.date);
        setEditVideoUrl(capsula.video_url);
        setEditExposes(capsula.exposes);
        setEditExposesSubtitle(capsula.exposes_subtitle);
        setEditDescription(capsula.description);
        setEditPriority(capsula.priority);
        setEditPublished(capsula.published ? "yes" : "no");
        setEditUser(formatUsersIntoSelector(users.find(user => user.username === capsula.username)));
        setEditModal(true);
    };

    const handleCloseEditModal = () => {
        setSelectedCapsula(null);
        setEditModal(false);
    };

    const handleOpenSuspendModal = (capsula) => {
        setSelectedCapsula(capsula);
        setSuspendModal(true);
    };

    const handleCloseSuspendModal = () => {
        setSelectedCapsula(null);
        setSuspendModal(false);
    };

    const handleOpenDeleteModal = (capsula) => {
        setSelectedCapsula(capsula);
        setDeleteModal(true);
    }

    const handleCloseDeleteModal = () => {
        setDeleteModal(false);
    };

    const resetTemplateFile = () => {
        setTemplateNewFile(null);
        setTemplateFeedback(null);
    }

    const handleTemplateUploadChange = (event) => {
        setTemplateNewFile(event.target.files[0]);
        setTemplateFeedback({ "color": "green", "show_buttons": true, "text": "Archivo seleccionado: " + event.target.files[0].name });
    };

    const handleUploadNewTemplate = async () => {
        if (!templateNewFile) {
            setTemplateFeedback({ "color": "#d32f2f", "show_buttons": false, "text": "Por favor seleccione un archivo." });
            return;
        }
        setFormLoading(true);
        let formData = new FormData();
        formData.append("file", templateNewFile);
        formData.append("kind", templateType.toUpperCase());
        formData.append("university", templateUniversity);
        let response = await updateCapsulaTemplate(api, formData);
        if (!response.capsulas) {
            setAlertError(true);
            setAlerts({ ...alerts, "error": { "title": "Error al actualizar plantilla", "text": response.error } });
        } else {
            setCapsulas(response.capsulas);
            setTemplateFeedback(null);
            setAlertSuccess(true);
            setAlerts({ ...alerts, "success": { "title": "¡Plantilla actualizada!", "text": "La plantilla se ha actualizado correctamente." } });
        }
        setFormLoading(false);
    }

    const handleDownloadTemplate = async () => {
        let downloadStatus = await downloadCapsulaTemplate(api, templateType.toUpperCase(), templateUniversity);
        if (!downloadStatus.success) {
            setTemplateFeedback({ "color": "#d32f2f", "show_buttons": false, "text": downloadStatus.error });
        } else {
            setAlertSuccess(true)
            setAlerts({ ...alerts, "success": { "title": "¡Plantilla descargada!", "text": "La plantilla se ha descargado correctamente." } });
            setTemplateFeedback(null)
        }
    }

    const handleDownloadCSV = () => {
        let downloadOptions = {
            title: csvTitle,
            description: csvDescription,
            exposes: csvExposes,
            date: csvDate,
            kind: csvKind,
            priority: csvPriority,
            published: csvPublished,
            video_url: csvVideoUrl,
            url: csvUrl,
            viewCount: csvViewCount,
            likeCount: csvLikeCount,
            commentCount: csvCommentCount,
            favoriteCount: csvFavoriteCount,
            user: csvUser
        }
        capsulasCSV(csvExcel, capsulas.capsulas, downloadOptions);
        handleCloseDownloadCSVModal();
        setAlertSuccess(true);
        setAlerts({ ...alerts, "success": { "title": "¡Archivo descargado!", "text": "Los datos se han descargado correctamente." } });
    }

    const handleCreateCapsula = async () => {
        const [isValid, tempErrors] = validateCapsulasForm(title, kind, date, video_url, exposes, exposesSubtitle, description, priority, published, user);
        if (!isValid) {
            setErrors(tempErrors);
            return;
        }
        setFormLoading(true);
        let formData = {
            title: title,
            kind: kind,
            date: date,
            video_url: video_url,
            exposes: exposes,
            exposes_subtitle: exposesSubtitle,
            description: description,
            priority: parseInt(priority),
            published: published === "yes",
            username: user.username
        }
        const response_create = await createCapsula(api, formData);
        setCapsulas(response_create.capsulas);
        setFormLoading(false);
        handleCloseCreateModal();
        setAlertSuccess(true);
        setAlerts({ ...alerts, "success": { "title": "¡Cápsula creada!", "text": "La cápsula se ha creado correctamente." + (published === "yes" ? " Quedó visible para usuarios autenticados en Aprendizaje Profesional." : "") } });
    }

    const handleEditCapsula = async () => {
        const [isValid, tempErrors] = validateCapsulasForm(editTitle, editKind, editDate, editVideoUrl, editExposes, editExposesSubtitle, editDescription, editPriority, editPublished, editUser);
        if (!isValid) {
            setEditErrors(tempErrors);
            return;
        }
        setFormLoading(true);
        let formData = {
            id: selectedCapsula.id,
            title: editTitle,
            kind: editKind,
            date: editDate,
            video_url: editVideoUrl,
            exposes: editExposes,
            exposes_subtitle: editExposesSubtitle,
            description: editDescription,
            priority: parseInt(editPriority),
            published: editPublished === "yes",
            username: editUser.username
        }
        const response = await editCapsula(api, formData);
        setCapsulas(response.capsulas);
        setFormLoading(false);
        handleCloseEditModal();
        setAlertSuccess(true);
        setAlerts({ ...alerts, "success": { "title": "¡Cápsula actualizada!", "text": "La cápsula se ha actualizado correctamente." } });
    }

    const handleToggleCapsulaVisibility = async (id, published) => {
        setFormLoading(true);
        const response = await toggleCapsulaVisibility(api, id, published);
        setCapsulas(response.capsulas);
        setFormLoading(false);
        handleCloseSuspendModal();
        setAlertSuccess(true);
        setAlerts({ ...alerts, "success": { "title": `¡Cápsula ${published ? "publicada" : "ocultada"}!`, "text": `La cápsula ${!published ? "ya no" : "ahora"} es visible ${published ? "para usuarios autenticados" : ""} en Aprendizaje Profesional.` } });
    }

    const handleDeleteCapsula = async (id) => {
        setFormLoading(true);
        const response = await deleteCapsula(api, id);
        setCapsulas(response.capsulas);
        setFormLoading(false);
        handleCloseDeleteModal();
        setAlertSuccess(true);
        setAlerts({ ...alerts, "success": { "title": "¡Cápsula eliminada!", "text": "La cápsula ha sido eliminada correctamente." } });
    }

    return (
        <Box>
            <Grid spacing={2} container justifyContent={"center"}>
                <Grid item xs={3}>
                    <Indicator title={"Cápsulas publicadas"} number={
                        capsulas.capsulas.filter(capsula => capsula.published).length
                    } titleSize={"small"} subtitleSize={"none"} helpMessage={
                        "Número de cápsulas publicadas en RedFID Aprendizaje Profesional."
                    }></Indicator>
                </Grid>
                <Grid item xs={3}>
                    <Indicator title={"Vistas totales"} number={
                        capsulas.capsulas.map(capsula => parseInt(capsula.viewCount)).reduce((a, b) => a + b, 0)
                    } titleSize={"small"} subtitleSize={"none"} helpMessage={
                        "Número total de visitas a cápsulas publicadas en RedFID Aprendizaje Profesional."
                    }></Indicator>
                </Grid>
                <Grid item xs={3}>
                    <Indicator title={"Usuarios colaboradores"} number={
                        capsulas.capsulas.map(capsula => capsula.username).filter((value, index, self) => self.indexOf(value) === index).length
                    } titleSize={"small"} subtitleSize={"none"} helpMessage={
                        "Número de usuarios que han publicado cápsulas en RedFID Aprendizaje Profesional."
                    }></Indicator>
                </Grid>
            </Grid>
            <CapsulasTable
                capsulas={capsulas.capsulas}
                handleOpenCreateModal={handleOpenCreateModal}
                handleOpenStatsModal={handleOpenStatsModal}
                handleOpenDownloadCSVModal={handleOpenDownloadCSVModal}
                handleOpenInfoModal={handleOpenInfoModal}
                handleOpenEditModal={handleOpenEditModal}
                handleOpenSuspendModal={handleOpenSuspendModal}
                handleOpenDeleteModal={handleOpenDeleteModal}
            ></CapsulasTable>
            <Grid display={"flex"} alignItems={"center"} justifyContent={"center"} mt={"20px"} spacing={2} container>
                <Grid item xs={12}>
                    <Card width="100%">
                        <CardHeader
                            title="Plantillas"
                            titleTypographyProps={{ "variant": "table-title" }}
                        />
                        <CardContent>
                            <Grid container spacing={2} alignItems={"center"}>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        fullWidth
                                        select
                                        label="Tipo de cápsula"
                                        variant="outlined"
                                        value={templateType}
                                        onChange={(e) => {
                                            setTemplateType(e.target.value)
                                        }}
                                    >
                                        <MenuItem value="none" disabled>Por favor seleccione una opción...</MenuItem>
                                        <MenuItem value="rep">Estudio propio</MenuItem>
                                        <MenuItem value="rhii">Habilidades para la innovación e investigación</MenuItem>
                                        <MenuItem value="reac">Elementos a considerar para enseñar algo</MenuItem>
                                        <MenuItem value="reo">Estudio realizado por otro</MenuItem>
                                        <MenuItem value="rap">Actividad pedagógica</MenuItem>
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        SelectProps={{
                                            MenuProps: {
                                                PaperProps: {
                                                    style: {
                                                        maxHeight: 300,
                                                    },
                                                }
                                            }
                                        }}
                                        fullWidth
                                        select
                                        label="Universidad"
                                        variant="outlined"
                                        value={templateUniversity}
                                        onChange={(e) => {
                                            setTemplateUniversity(e.target.value)
                                        }}
                                    >
                                        <MenuItem value="none" disabled>Por favor seleccione una opción...</MenuItem>
                                        {
                                            Object.keys(attributes.university.options).map((university, index) => {
                                                return <MenuItem key={index} value={university}>{attributes.university.options[university]}</MenuItem>
                                            })
                                        }
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    {(templateType !== "none" && templateUniversity !== "none") &&
                                        <Box textAlign={"center"} >
                                            <Button onClick={handleDownloadTemplate} sx={{ "fontSize": { "xs": "0.7em", "sm": "0.9em" }, margin: { xs: "0px 10px 0px 0px", sm: "0px", md: "0px 10px 0px 0px" } }} type="button" variant="green" style={{ "textTransform": "none" }} startIcon={<DownloadIcon />}>
                                                {"Descargar"}
                                            </Button>
                                            <Button
                                                onChange={handleTemplateUploadChange}
                                                component="label"
                                                role={undefined}
                                                tabIndex={-1}
                                                sx={{ "fontSize": { "xs": "0.7em", "sm": "0.9em" }, margin: { xs: "0px 10px 0px 0px", sm: "10px 0", md: "0px 10px 0px 0px" } }}
                                                variant="green"
                                                style={{ "textTransform": "none" }}
                                                startIcon={<UploadIcon />}
                                            >
                                                Actualizar
                                                <FileUpload type="file" onChange={handleTemplateUploadChange} accept={".potx"} />
                                            </Button>
                                        </Box>
                                    }
                                </Grid>
                            </Grid>
                            {templateFeedback &&
                                <Box textAlign={"center"} display={"flex"} alignItems={"center"} justifyContent={"center"} mt={"20px"} mb={"10px"}>
                                    <Typography variant="p" color={templateFeedback["color"]}>
                                        {templateFeedback["text"]}
                                    </Typography>
                                    {templateFeedback["show_buttons"] &&
                                        <IconButton onClick={() => resetTemplateFile()} sx={{ "marginLeft": "10px" }}>
                                            <CloseIcon />
                                        </IconButton>
                                    }
                                    {templateFeedback["show_buttons"] &&
                                        <IconButton onClick={() => handleUploadNewTemplate()} sx={{ "marginLeft": "10px" }}>
                                            <CheckIcon />
                                        </IconButton>
                                    }
                                </Box>
                            }
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <Modal
                open={createModal}
                handleClose={handleCloseCreateModal}
                title={"Crear nueva cápsula"}
                onClickMainButton={handleCreateCapsula}
                mainButtonText={"Crear"}
                width={"lg"}
                cancelButton={true}
                startIcon={<AddCircleOutlineIcon />}
                content={
                    <>
                        <Typography variant="p" textAlign={"justify"} mb={"20px"}>
                            Aquí puede crear una nueva cápsula y dejarla publicado en RedFID Aprendizaje Profesional.
                            En primer lugar, debe subirse a YouTube el vídeo de la cápsula, idealmente con la cuenta de RedFID.
                            Luego, complete el siguiente formulario con la información correspondiente.
                        </Typography>
                        {(users) ?
                            <CapsulaForm
                                users={users}
                                title={title}
                                setTitle={setTitle}
                                kind={kind}
                                setKind={setKind}
                                date={date}
                                setDate={setDate}
                                video_url={video_url}
                                setVideoUrl={setVideoUrl}
                                exposes={exposes}
                                setExposes={setExposes}
                                exposesSubtitle={exposesSubtitle}
                                setExposesSubtitle={setExposesSubtitle}
                                priority={priority}
                                setPriority={setPriority}
                                published={published}
                                setPublished={setPublished}
                                description={description}
                                setDescription={setDescription}
                                user={user}
                                setUser={setUser}
                                formLoading={formLoading}
                                errors={errors}
                                setErrors={setErrors}
                            ></CapsulaForm>
                            : <Box textAlign={"center"}><CircularProgress sx={{ "color": "#40b4ba" }} /></Box>}
                    </>
                }
            ></Modal>
            <Modal
                open={statsModal}
                handleClose={handleCloseStatsModal}
                title={"Estadísticas de cápsulas"}
                onClickMainButton={handleCloseStatsModal}
                mainButtonText={"OK"}
                hideMainButton={true}
                width={"lg"}
                cancelButton={false}
                startIcon={null}
                content={
                    <>
                        <Grid spacing={2} container>
                            <Grid item xs={12} md={6}>
                                <Typography variant="h6">Cápsulas por categoría</Typography>
                                <PieChart
                                    id={"capsulas-by-kind"}
                                    height={"300px"}
                                    data={() => {
                                        let out = [
                                            {
                                                "label": translateCapsulaKind("rep"),
                                                "value": 0,
                                                "color": colorsCapsulaKind("rep")
                                            },
                                            {
                                                "label": translateCapsulaKind("rhii"),
                                                "value": 0,
                                                "color": colorsCapsulaKind("rhii")
                                            },
                                            {
                                                "label": translateCapsulaKind("reac"),
                                                "value": 0,
                                                "color": colorsCapsulaKind("reac")
                                            },
                                            {
                                                "label": translateCapsulaKind("reo"),
                                                "value": 0,
                                                "color": colorsCapsulaKind("reo")
                                            },
                                            {
                                                "label": translateCapsulaKind("rap"),
                                                "value": 0,
                                                "color": colorsCapsulaKind("rap")
                                            }
                                        ];
                                        for (let capsula of capsulas.capsulas) {
                                            let kind = out.find(kind => kind.label === translateCapsulaKind(capsula.kind));
                                            kind.value++;
                                        }
                                        return out;
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography variant="h6">Cápsulas más vistas</Typography>
                                <BarChart
                                    id={"capsulas-by-views"}
                                    sort={true}
                                    height={"300px"}
                                    horizontal={true}
                                    data={() => {
                                        let out = [];
                                        for (let capsula of capsulas.capsulas) {
                                            out.push({ "label": capsula.title, "value": parseInt(capsula.viewCount), color: "#40b4ba" });
                                        }
                                        return out;
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </>
                }
            ></Modal>
            <Modal
                open={downloadCSVModal}
                handleClose={handleCloseDownloadCSVModal}
                title={"Descargar datos"}
                onClickMainButton={handleDownloadCSV}
                mainButtonText={"Descargar"}
                width={"lg"}
                cancelButton={true}
                startIcon={<DownloadIcon />}
                content={
                    <>
                        <Typography variant="p" textAlign={"justify"} mb={"20px"}>
                            Se descargará un archivo en formato .csv con la información de todas las cápsulas de RedFID.
                            Por favor, seleccione los campos que desea incluir:
                        </Typography>
                        <DownloadCSVCapsulasForm
                            csvTitle={csvTitle}
                            setCsvTitle={setCsvTitle}
                            csvDescription={csvDescription}
                            setCsvDescription={setCsvDescription}
                            csvExposes={csvExposes}
                            setCsvExposes={setCsvExposes}
                            csvDate={csvDate}
                            setCsvDate={setCsvDate}
                            csvKind={csvKind}
                            setCsvKind={setCsvKind}
                            csvPriority={csvPriority}
                            setCsvPriority={setCsvPriority}
                            csvPublished={csvPublished}
                            setCsvPublished={setCsvPublished}
                            csvVideoUrl={csvVideoUrl}
                            setCsvVideoUrl={setCsvVideoUrl}
                            csvUrl={csvUrl}
                            setCsvUrl={setCsvUrl}
                            csvViewCount={csvViewCount}
                            setCsvViewCount={setCsvViewCount}
                            csvLikeCount={csvLikeCount}
                            setCsvLikeCount={setCsvLikeCount}
                            csvCommentCount={csvCommentCount}
                            setCsvCommentCount={setCsvCommentCount}
                            csvFavoriteCount={csvFavoriteCount}
                            setCsvFavoriteCount={setCsvFavoriteCount}
                            csvUser={csvUser}
                            setCsvUser={setCsvUser}
                            csvExcel={csvExcel}
                            setCsvExcel={setCsvExcel}
                        ></DownloadCSVCapsulasForm>
                    </>
                }
            ></Modal>
            {selectedCapsula &&
                <>
                    <Modal
                        open={infoModal}
                        handleClose={handleCloseInfoModal}
                        title={"Información detallada"}
                        onClickMainButton={handleCloseInfoModal}
                        mainButtonText={"OK"}
                        hideMainButton={true}
                        width={"md"}
                        cancelButton={false}
                        startIcon={null}
                        content={
                            <>
                                <Grid container columnSpacing={2}>
                                    <Grid item mb={"10px"} xs={12} display={"flex"} flexDirection={"column"} justifyContent={"space-between"}>
                                        <Typography variant='p' mb={"10px"}><b>Descripción:</b> {selectedCapsula.description}</Typography>
                                        <Typography variant='p' mb={"10px"}><b>URL en RedFID:</b> <Link color="#40b4ba" target="_blank" href={process.env.REACT_APP_CURSOS_URL + "/dashboard?display=2&displayId=" + selectedCapsula.id.toString()}>{process.env.REACT_APP_CURSOS_URL + "/dashboard?display=2&displayId=" + selectedCapsula.id.toString()}</Link></Typography>
                                        <Typography variant='p' mb={"10px"}><b>URL en YouTube:</b> <Link color="#40b4ba" target="_blank" href={selectedCapsula.video_url}>{selectedCapsula.video_url}</Link></Typography>
                                    </Grid>
                                    <Grid item mb={"10px"} xs={12} display={"flex"} alignItems={"center"}>
                                        <Typography variant='p' mr={"10px"}><b>Tipo:</b></Typography>{getCapsulaChip(selectedCapsula.kind)}
                                    </Grid>
                                    <Grid item mb={"10px"} xs={12} md={6} display={"flex"} flexDirection={"column"} justifyContent={"space-between"}>
                                        <Typography variant='p' mb={"10px"}><b>Fecha: </b>{selectedCapsula.date}</Typography>
                                        <Typography variant='p' mb={"10px"}><b>Expositor: </b>{selectedCapsula.exposes}</Typography>
                                        <Typography variant='p' mb={"10px"}><b>Usuario colaborador: </b>{selectedCapsula.username}</Typography>
                                    </Grid>
                                    <Grid item mb={"10px"} xs={12} md={6} display={"flex"} flexDirection={"column"}>
                                        <Box display={"flex"} mb={"10px"}>
                                            <Typography variant='p' mr={"10px"}><b>Publicado: </b></Typography>
                                            {selectedCapsula.published ? <CheckCircleIcon sx={{ "color": "green" }}></CheckCircleIcon> : <CancelIcon sx={{ "color": "red" }}></CancelIcon>}
                                        </Box>
                                        <Typography variant='p' mb={"10px"}><b>Prioridad: </b>{selectedCapsula.priority}</Typography>
                                        <Typography variant='p' mb={"10px"}><b>Visualizaciones en YouTube: </b>{selectedCapsula.viewCount}</Typography>
                                    </Grid>
                                    <Grid item xs={12} display={"flex"} justifyContent={"center"}>
                                        <YouTube videoId={selectedCapsula.video_url.split("v=")[1]} opts={opts} onReady={onPlayerReady} />
                                    </Grid>
                                </Grid>
                            </>
                        }
                    ></Modal>
                    <Modal
                        open={editModal}
                        handleClose={handleCloseEditModal}
                        title={"Editar cápsula"}
                        onClickMainButton={handleEditCapsula}
                        mainButtonText={"Editar"}
                        width={"lg"}
                        cancelButton={true}
                        startIcon={<EditIcon />}
                        content={
                            <>
                                <Typography variant="p" textAlign={"justify"} mb={"30px"}>
                                    Aquí puede editar la información de la cápsula seleccionada.
                                </Typography>
                                <CapsulaForm
                                    users={users}
                                    title={editTitle}
                                    setTitle={setEditTitle}
                                    kind={editKind}
                                    setKind={setEditKind}
                                    date={editDate}
                                    setDate={setEditDate}
                                    video_url={editVideoUrl}
                                    setVideoUrl={setEditVideoUrl}
                                    exposes={editExposes}
                                    setExposes={setEditExposes}
                                    exposesSubtitle={editExposesSubtitle}
                                    setExposesSubtitle={setEditExposesSubtitle}
                                    priority={editPriority}
                                    setPriority={setEditPriority}
                                    published={editPublished}
                                    setPublished={setEditPublished}
                                    description={editDescription}
                                    setDescription={setEditDescription}
                                    user={editUser}
                                    setUser={setEditUser}
                                    formLoading={formLoading}
                                    errors={editErrors}
                                    setErrors={setEditErrors}
                                ></CapsulaForm>
                            </>
                        }
                    ></Modal>
                    <Modal
                        open={suspendModal}
                        handleClose={handleCloseSuspendModal}
                        title={selectedCapsula.published ? "Ocultar cápsula" : "Publicar cápsula"}
                        onClickMainButton={() => handleToggleCapsulaVisibility(selectedCapsula.id, !selectedCapsula.published)}
                        mainButtonText={selectedCapsula.published ? "Ocultar" : "Publicar"}
                        width={"md"}
                        cancelButton={true}
                        startIcon={selectedCapsula.published ? <BlockIcon /> : <CheckIcon />}
                        content={
                            <>
                                <Typography variant="p" textAlign={"justify"} mb={"20px"}>
                                    {selectedCapsula.published ? "¿Está segur@ que desea ocultar la cápsula seleccionada?" : "¿Está segur@ que desea publicar la cápsula seleccionada?"}
                                </Typography>
                                <Typography variant="p" textAlign={"center"} mb={"20px"}>
                                    <b>{selectedCapsula.title}</b>
                                </Typography>
                                <Typography variant="p" textAlign={"justify"} mb={"20px"}>
                                    La cápsula seleccionada {selectedCapsula.published ? "ya no" : "ahora"} será visible para usuarios autenticados en RedFID Aprendizaje Profesional.
                                </Typography>
                            </>
                        }
                    ></Modal>
                    <Modal
                        open={deleteModal}
                        handleClose={handleCloseDeleteModal}
                        title={"Eliminar cápsula"}
                        onClickMainButton={() => handleDeleteCapsula(selectedCapsula.id)}
                        mainButtonText={"Eliminar"}
                        width={"md"}
                        cancelButton={true}
                        startIcon={<DeleteForeverIcon />}
                        content={
                            <>
                                <Typography variant="p" textAlign={"justify"} mb={"20px"}>
                                    ¿Está segur@ que desea eliminar la cápsula seleccionada?
                                </Typography>
                                <Typography variant="p" textAlign={"center"} mb={"20px"}>
                                    <b>{selectedCapsula.title}</b>
                                </Typography>
                                <Typography variant="p" textAlign={"justify"} mb={"20px"}>
                                    La cápsula seleccionada será eliminada de RedFID Aprendizaje Profesional. Esta acción es irreversible.
                                </Typography>
                            </>
                        }
                    ></Modal>
                </>

            }
        </Box>
    )
}

export default Capsulas;
