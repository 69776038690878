import React, { useState, useEffect } from 'react';
import YouTube, { YouTubeProps } from 'react-youtube';
import { Box, CircularProgress, Grid, Typography, Link, Chip } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForever from '@mui/icons-material/DeleteForever';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import TalleresYWebinarsTable from './components/tables/TalleresYWebinarsTable';
import Modal from './components/Modal';
import Indicator from './components/Indicator';
import TallerWebinarForm from './components/forms/TallerWebinarForm';
import DownloadCSVTalleresYWebinarsForm from './components/forms/DownloadCSVTalleresYWebinarsForm';
import { talleresYWebinarsCSV } from '../utils/csvDownload';
import { validateTalleresYWebinarsForm } from '../utils/validators';
import { editTalleresYWebinars } from '../requests/talleresywebinars/editTalleresYWebinars';
import { toggleTalleresYWebinarsVisibility } from '../requests/talleresywebinars/toggleTalleresYWebinarsVisibility';
import { deleteTalleresYWebinars } from '../requests/talleresywebinars/deleteTalleresYWebinars';
import { createTalleresYWebinars } from '../requests/talleresywebinars/createTalleresYWebinars';
import { api } from '../API';
import BarChart from './components/charts/BarChart';
import LineChart from './components/charts/LineChart';
import PieChart from './components/charts/PieChart';

const TalleresYWebinars = ({
    setAlertSuccess,
    setAlertWarning,
    setAlertError,
    alerts,
    setAlerts,
    talleresYWebinars,
    setTalleresYWebinars,
    events,
    setEvents
}) => {

    const [selectedTallerWebinar, setSelectedTallerWebinar] = useState(null);
    const [formLoading, setFormLoading] = useState(false);
    const [createModal, setCreateModal] = useState(false);
    const [statsModal, setStatsModal] = useState(false);
    const [downloadCSVModal, setDownloadCSVModal] = useState(false);
    const [infoModal, setInfoModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [suspendModal, setSuspendModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);

    const [csvTitle, setCsvTitle] = useState(true);
    const [csvDescription, setCsvDescription] = useState(true);
    const [csvExposes, setCsvExposes] = useState(true);
    const [csvDate, setCsvDate] = useState(true);
    const [csvKind, setCsvKind] = useState(true);
    const [csvPriority, setCsvPriority] = useState(true);
    const [csvPublished, setCsvPublished] = useState(true);
    const [csvVideoUrl, setCsvVideoUrl] = useState(true);
    const [csvUrl, setCsvUrl] = useState(true);
    const [csvViewCount, setCsvViewCount] = useState(true);
    const [csvLikeCount, setCsvLikeCount] = useState(true);
    const [csvCommentCount, setCsvCommentCount] = useState(true);
    const [csvFavoriteCount, setCsvFavoriteCount] = useState(true);
    const [csvAttendants, setCsvAttendants] = useState(true);
    const [csvExcel, setCsvExcel] = useState(true);

    const [title, setTitle] = useState("");
    const [kind, setKind] = useState("none");
    const [date, setDate] = useState("");
    const [video_url, setVideoUrl] = useState("");
    const [exposes, setExposes] = useState("");
    const [description, setDescription] = useState("");
    const [priority, setPriority] = useState("");
    const [published, setPublished] = useState("none");
    const [associated, setAssociated] = useState("none");
    const [errors, setErrors] = useState({});

    const [editTitle, setEditTitle] = useState("");
    const [editKind, setEditKind] = useState("none");
    const [editDate, setEditDate] = useState("");
    const [editVideoUrl, setEditVideoUrl] = useState("");
    const [editExposes, setEditExposes] = useState("");
    const [editDescription, setEditDescription] = useState("");
    const [editPriority, setEditPriority] = useState("");
    const [editPublished, setEditPublished] = useState("none");
    const [editAssociated, setEditAssociated] = useState("none");
    const [editErrors, setEditErrors] = useState({});

    const onPlayerReady = (event) => {
        event.target.pauseVideo();
    }

    const opts = {
        height: '390',
        width: '640',
        playerVars: {
            autoplay: 1,
        },
    };

    const handleOpenDownloadCSVModal = () => {
        setDownloadCSVModal(true);
    }

    const handleCloseDownloadCSVModal = () => {
        setDownloadCSVModal(false);
    }

    const handleOpenStatsModal = () => {
        setStatsModal(true);
    }

    const handleCloseStatsModal = () => {
        setStatsModal(false);
    }

    const handleOpenFormModal = () => {
        setCreateModal(true);
    }

    const handleCloseFormModal = () => {
        setCreateModal(false);
    }

    const handleOpenInfoModal = (tallerWebinar) => {
        setSelectedTallerWebinar(tallerWebinar);
        setInfoModal(true);
    };

    const handleCloseInfoModal = () => {
        setSelectedTallerWebinar(null);
        setInfoModal(false);
    };

    const handleOpenEditModal = (tallerWebinar) => {
        setSelectedTallerWebinar(tallerWebinar);
        setEditTitle(tallerWebinar.title);
        setEditKind(tallerWebinar.kind);
        setEditDate(tallerWebinar.date);
        setEditVideoUrl(tallerWebinar.video_url);
        setEditExposes(tallerWebinar.exposes);
        setEditDescription(tallerWebinar.description);
        setEditPriority(tallerWebinar.priority);
        setEditPublished(tallerWebinar.published ? "yes" : "no");
        setEditAssociated(tallerWebinar.associated);
        setEditModal(true);
    };

    const handleCloseEditModal = () => {
        setSelectedTallerWebinar(null);
        setEditModal(false);
    };

    const handleOpenSuspendModal = (tallerWebinar) => {
        setSelectedTallerWebinar(tallerWebinar);
        setSuspendModal(true);
    };

    const handleCloseSuspendModal = () => {
        setSelectedTallerWebinar(null);
        setSuspendModal(false);
    };

    const handleOpenDeleteModal = (tallerWebinar) => {
        setSelectedTallerWebinar(tallerWebinar);
        setDeleteModal(true);
    }

    const handleCloseDeleteModal = () => {
        setSelectedTallerWebinar(null);
        setDeleteModal(false);
    };

    const handleDownloadCSV = () => {
        let downloadOptions = {
            title: csvTitle,
            description: csvDescription,
            exposes: csvExposes,
            date: csvDate,
            kind: csvKind,
            priority: csvPriority,
            published: csvPublished,
            video_url: csvVideoUrl,
            url: csvUrl,
            viewCount: csvViewCount,
            likeCount: csvLikeCount,
            commentCount: csvCommentCount,
            favoriteCount: csvFavoriteCount,
            attendants: csvAttendants
        }
        talleresYWebinarsCSV(csvExcel, talleresYWebinars, events, downloadOptions);
        handleCloseDownloadCSVModal();
        setAlertSuccess(true);
        setAlerts({ ...alerts, "success": { "title": "¡Archivo descargado!", "text": "Los datos se han descargado correctamente." } });
    }

    const handleCreateTalleresYWebinars = async () => {
        const [isValid, tempErrors] = validateTalleresYWebinarsForm(title, kind, date, video_url, exposes, description, priority, published, associated);
        if (!isValid) {
            setErrors(tempErrors);
            return;
        }
        setFormLoading(true);
        let formData = {
            title: title,
            kind: kind,
            date: date,
            video_url: video_url,
            exposes: exposes,
            description: description,
            priority: parseInt(priority),
            published: published === "yes",
            associated: associated
        }
        const response_create = await createTalleresYWebinars(api, formData);
        setTalleresYWebinars(response_create.talleresYWebinars);
        setEvents(response_create.events);
        setFormLoading(false);
        handleCloseFormModal();
        setAlertSuccess(true);
        setAlerts({ ...alerts, "success": { "title": `${kind.replace("t", "T").replace("w", "W")} creado!`, "text": `El ${kind} se ha creado correctamente.` + (published === "yes" ? " Quedó visible para usuarios autenticados en Aprendizaje Profesional." : "") } });
        setTitle("");
        setKind("none");
        setDate("");
        setVideoUrl("");
        setExposes("");
        setDescription("");
        setPriority("");
        setAssociated("none");
        setPublished("none");
    }

    const handleEditTalleresYWebinars = async () => {
        const [isValid, tempErrors] = validateTalleresYWebinarsForm(editTitle, editKind, editDate, editVideoUrl, editExposes, editDescription, editPriority, editPublished, editAssociated);
        if (!isValid) {
            setEditErrors(tempErrors);
            return;
        }
        setFormLoading(true);
        let formData = {
            id: selectedTallerWebinar.id,
            title: editTitle,
            kind: editKind,
            date: editDate,
            video_url: editVideoUrl,
            exposes: editExposes,
            description: editDescription,
            priority: editPriority,
            published: editPublished === "yes",
            associated: editAssociated
        }
        const response = await editTalleresYWebinars(api, formData);
        setTalleresYWebinars(response.talleresYWebinars);
        setEvents(response.events);
        setFormLoading(false);
        handleCloseEditModal();
        setAlertSuccess(true);
        setAlerts({ ...alerts, "success": { "title": `${editKind.replace("t", "T").replace("w", "W")} editado!`, "text": `El ${editKind} se ha editado correctamente.` } });
    }

    const handleToggleTalleresYWebinarsVisibility = async (id, published) => {
        setFormLoading(true);
        const response = await toggleTalleresYWebinarsVisibility(api, id, published);
        setTalleresYWebinars(response.talleresYWebinars);
        setFormLoading(false);
        handleCloseSuspendModal();
        setAlertSuccess(true);
        setAlerts({ ...alerts, "success": { "title": `${selectedTallerWebinar.kind.replace("t", "T").replace("w", "W")} ${published ? "ocultado" : "activado"}!`, "text": `El ${selectedTallerWebinar.kind} se ha ${published ? "ocultado" : "activado"} correctamente.` } });
    }

    const handleDeleteTalleresYWebinars = async (id) => {
        setFormLoading(true);
        const response = await deleteTalleresYWebinars(api, id);
        setTalleresYWebinars(response.talleresYWebinars);
        setEvents(response.events);
        setFormLoading(false);
        handleCloseDeleteModal();
        setAlertSuccess(true);
        setAlerts({ ...alerts, "success": { "title": `${selectedTallerWebinar.kind.replace("t", "T").replace("w", "W")} eliminado!`, "text": `El ${selectedTallerWebinar.kind} se ha eliminado correctamente. Ya no se encuentra disponible en RedFID Aprendizaje Profesional.` } });
    }

    return (
        <>
            <Box>
                <Grid spacing={2} container justifyContent={"center"}>
                    <Grid item xs={3}>
                        <Indicator title={"Talleres publicados"} number={
                            talleresYWebinars.filter((taller) => taller.kind === "taller").length
                        } titleSize={"small"} subtitleSize={"none"} helpMessage={
                            "Número de talleres publicados en RedFID Aprendizaje Profesional."
                        }></Indicator>
                    </Grid>
                    <Grid item xs={3}>
                        <Indicator title={"Webinars publicados"} number={
                            talleresYWebinars.filter((taller) => taller.kind === "webinar").length
                        } titleSize={"small"} subtitleSize={"none"} helpMessage={
                            "Número de webinars publicados en RedFID Aprendizaje Profesional."
                        }></Indicator>
                    </Grid>
                    <Grid item xs={3}>
                        <Indicator title={"Vistas totales"} number={
                            talleresYWebinars.reduce((acc, taller) => acc + parseInt(taller.viewCount), 0)
                        } titleSize={"small"} subtitleSize={"none"} helpMessage={
                            "Número total de vistas en YouTube de los talleres y webinars publicados en RedFID Aprendizaje Profesional."
                        }></Indicator>
                    </Grid>
                </Grid>
                <TalleresYWebinarsTable
                    talleresYWebinars={talleresYWebinars}
                    events={events}
                    handleOpenCreateModal={handleOpenFormModal}
                    handleOpenStatsModal={handleOpenStatsModal}
                    handleOpenDownloadCSVModal={handleOpenDownloadCSVModal}
                    handleOpenInfoModal={handleOpenInfoModal}
                    handleOpenEditModal={handleOpenEditModal}
                    handleOpenSuspendModal={handleOpenSuspendModal}
                    handleOpenDeleteModal={handleOpenDeleteModal}
                ></TalleresYWebinarsTable>
                <Modal
                    open={createModal}
                    title={"Crear taller/webinar"}
                    handleClose={handleCloseFormModal}
                    onClickMainButton={handleCreateTalleresYWebinars}
                    mainButtonText={"Crear"}
                    width={"lg"}
                    cancelButton={true}
                    startIcon={<AddCircleOutlineIcon></AddCircleOutlineIcon>}
                    content={
                        <>
                            <Typography variant="p" textAlign={"justify"} mb={"20px"}>
                                Aquí puede crear un nuevo taller/webinar y dejarlo publicado en RedFID Aprendizaje Profesional.
                                En primer lugar, debe subirse el vídeo del taller/webinar a YouTube, idealmente con la cuenta de RedFID.
                                Luego, complete el siguiente formulario con la información correspondiente.
                            </Typography>
                            <TallerWebinarForm
                                title={title}
                                setTitle={setTitle}
                                kind={kind}
                                setKind={setKind}
                                date={date}
                                setDate={setDate}
                                video_url={video_url}
                                setVideoUrl={setVideoUrl}
                                exposes={exposes}
                                setExposes={setExposes}
                                priority={priority}
                                setPriority={setPriority}
                                published={published}
                                setPublished={setPublished}
                                description={description}
                                setDescription={setDescription}
                                associated={associated}
                                setAssociated={setAssociated}
                                formLoading={formLoading}
                                errors={errors}
                                setErrors={setErrors}
                                events={events}
                                entryId={null}
                            ></TallerWebinarForm>
                        </>
                    }
                ></Modal>
                <Modal
                    open={statsModal}
                    title={"Estadísticas de talleres y webinars"}
                    handleClose={handleCloseStatsModal}
                    onClickMainButton={handleCloseStatsModal}
                    mainButtonText={"OK"}
                    hideMainButton={true}
                    width={"lg"}
                    cancelButton={false}
                    content={
                        <>
                            <Grid spacing={2} container>
                                <Grid item xs={12} md={4}>
                                    <Typography variant="h6">Tipo de evento</Typography>
                                    <PieChart
                                        id={"talleres-by-kind"}
                                        height={"300px"}
                                        data={() => {
                                            let out = [
                                                {
                                                    "label": "Taller",
                                                    "value": 0,
                                                    "color": "#40b4ba"
                                                },
                                                {
                                                    "label": "Webinar",
                                                    "value": 0,
                                                    "color": "#eb947e"
                                                }
                                            ];
                                            for (let tw of talleresYWebinars) {
                                                let kind = out.find(kind => kind.label === (tw.kind === "taller" ? "Taller" : "Webinar"));
                                                kind.value++;
                                            }
                                            return out;
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <Typography variant="h6">Talleres más vistos</Typography>
                                    <BarChart
                                        id={"talleres-by-views"}
                                        sort={true}
                                        height={"300px"}
                                        horizontal={true}
                                        data={() => {
                                            let out = [];
                                            for (let tw of talleresYWebinars) {
                                                out.push({
                                                    "label": tw.title,
                                                    "value": parseInt(tw.viewCount),
                                                    "color": "#40b4ba"
                                                });
                                            }
                                            return out;
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h6">Asistencia mensual a talleres y webinars</Typography>
                                    <LineChart
                                        id={"talleres-by-attendants"}
                                        height={"300px"}
                                        tooltipFormatter={(value) => value + " asistencias"}
                                        timeInterval={"month"}
                                        data={() => {
                                            let out = [];
                                            for (let e of events.filter(e => e.associated !== 0)) {
                                                let date = new Date(e.startDate).toISOString().slice(0, 10).replace(/-/g, "");
                                                out.push({
                                                    "date": date,
                                                    "value": parseInt(e.attendants.length),
                                                    "color": "#eb947e"
                                                });
                                            }
                                            out = out.sort((a, b) => a.date - b.date);
                                            return out;
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </>
                    }
                ></Modal>
                <Modal
                    open={downloadCSVModal}
                    title={"Descargar datos"}
                    handleClose={handleCloseDownloadCSVModal}
                    onClickMainButton={handleDownloadCSV}
                    mainButtonText={"Descargar"}
                    width={"lg"}
                    startIcon={<DownloadIcon></DownloadIcon>}
                    cancelButton={true}
                    content={
                        <>
                            <Typography variant="p" textAlign={"justify"} mb={"20px"}>
                                Se descargará un archivo en formato .csv con la información de todos los talleres y webinars de RedFID.
                                Por favor, seleccione los campos que desea incluir:
                            </Typography>
                            <DownloadCSVTalleresYWebinarsForm
                                csvTitle={csvTitle}
                                setCsvTitle={setCsvTitle}
                                csvDescription={csvDescription}
                                setCsvDescription={setCsvDescription}
                                csvExposes={csvExposes}
                                setCsvExposes={setCsvExposes}
                                csvDate={csvDate}
                                setCsvDate={setCsvDate}
                                csvKind={csvKind}
                                setCsvKind={setCsvKind}
                                csvPriority={csvPriority}
                                setCsvPriority={setCsvPriority}
                                csvPublished={csvPublished}
                                setCsvPublished={setCsvPublished}
                                csvVideoUrl={csvVideoUrl}
                                setCsvVideoUrl={setCsvVideoUrl}
                                csvUrl={csvUrl}
                                setCsvUrl={setCsvUrl}
                                csvViewCount={csvViewCount}
                                setCsvViewCount={setCsvViewCount}
                                csvLikeCount={csvLikeCount}
                                setCsvLikeCount={setCsvLikeCount}
                                csvCommentCount={csvCommentCount}
                                setCsvCommentCount={setCsvCommentCount}
                                csvFavoriteCount={csvFavoriteCount}
                                setCsvFavoriteCount={setCsvFavoriteCount}
                                csvAttendants={csvAttendants}
                                setCsvAttendants={setCsvAttendants}
                                csvExcel={csvExcel}
                                setCsvExcel={setCsvExcel}
                            ></DownloadCSVTalleresYWebinarsForm>
                        </>
                    }
                ></Modal>
                {selectedTallerWebinar &&
                    <Modal
                        open={infoModal}
                        title={selectedTallerWebinar.title}
                        handleClose={handleCloseInfoModal}
                        onClickMainButton={handleCloseInfoModal}
                        mainButtonText={"OK"}
                        width={"md"}
                        cancelButton={false}
                        content={
                            <>
                                <Grid container columnSpacing={2}>
                                    <Grid item mb={"10px"} xs={12} display={"flex"} flexDirection={"column"} justifyContent={"space-between"}>
                                        <Typography variant='p' mb={"10px"}><b>Descripción:</b> {selectedTallerWebinar.description}</Typography>
                                        <Typography variant='p' mb={"10px"}><b>URL en RedFID:</b> <Link color="#40b4ba" target="_blank" href={process.env.REACT_APP_CURSOS_URL + "/dashboard?display=1&displayId=" + selectedTallerWebinar.id.toString()}>{process.env.REACT_APP_CURSOS_URL + "/dashboard?display=1&displayId=" + selectedTallerWebinar.id.toString()}</Link></Typography>
                                        <Typography variant='p' mb={"10px"}><b>URL en YouTube:</b> <Link color="#40b4ba" target="_blank" href={selectedTallerWebinar.video_url}>{selectedTallerWebinar.video_url}</Link></Typography>
                                    </Grid>
                                    <Grid item mb={"10px"} xs={12} display={"flex"} alignItems={"center"}>
                                        <Typography variant='p' mr={"10px"}><b>Tipo:</b></Typography>{<Chip variant={selectedTallerWebinar.kind === "taller" ? "green" : "orange"} label={selectedTallerWebinar.kind[0].toUpperCase() + selectedTallerWebinar.kind.slice(1)} />}
                                    </Grid>
                                    <Grid item mb={"10px"} xs={12} md={6} display={"flex"} flexDirection={"column"} justifyContent={"space-between"}>
                                        <Typography variant='p' mb={"10px"}><b>Fecha: </b>{selectedTallerWebinar.date}</Typography>
                                        <Typography variant='p' mb={"10px"}><b>Expositor: </b>{selectedTallerWebinar.exposes}</Typography>
                                        <Typography variant='p' mb={"10px"}><b>Asistentes a evento: </b>{events.find(e => e.id === selectedTallerWebinar.associated) ? events.find(e => e.id === selectedTallerWebinar.associated).attendants.length : "—"} </Typography>
                                    </Grid>
                                    <Grid item mb={"10px"} xs={12} md={6} display={"flex"} flexDirection={"column"}>
                                        <Box display={"flex"} mb={"10px"}>
                                            <Typography variant='p' mr={"10px"}><b>Publicado: </b></Typography>
                                            {selectedTallerWebinar.published ? <CheckCircleIcon sx={{ "color": "green" }}></CheckCircleIcon> : <CancelIcon sx={{ "color": "red" }}></CancelIcon>}
                                        </Box>
                                        <Typography variant='p' mb={"10px"}><b>Prioridad: </b>{selectedTallerWebinar.priority}</Typography>
                                        <Typography variant='p' mb={"10px"}><b>Visualizaciones en YouTube: </b>{selectedTallerWebinar.viewCount}</Typography>
                                    </Grid>
                                    <Grid item xs={12} display={"flex"} justifyContent={"center"}>
                                        <YouTube videoId={selectedTallerWebinar.video_url.split("v=")[1]} opts={opts} onReady={onPlayerReady} />
                                    </Grid>
                                </Grid>
                            </>
                        }
                    ></Modal>
                }
                {selectedTallerWebinar &&
                    <Modal
                        open={editModal}
                        title={"Editar " + (selectedTallerWebinar ? selectedTallerWebinar.kind : "")}
                        handleClose={handleCloseEditModal}
                        onClickMainButton={() => handleEditTalleresYWebinars()}
                        mainButtonText={"Editar"}
                        width={"lg"}
                        cancelButton={true}
                        startIcon={<EditIcon></EditIcon>}
                        content={
                            <>
                                <Typography variant="p" textAlign={"justify"} mb={"20px"}>
                                    Aquí puede editar la información del {selectedTallerWebinar ? selectedTallerWebinar.kind : ""} seleccionado.
                                </Typography>
                                <TallerWebinarForm
                                    title={editTitle}
                                    setTitle={setEditTitle}
                                    kind={editKind}
                                    setKind={setEditKind}
                                    date={editDate}
                                    setDate={setEditDate}
                                    video_url={editVideoUrl}
                                    setVideoUrl={setEditVideoUrl}
                                    exposes={editExposes}
                                    setExposes={setEditExposes}
                                    priority={editPriority}
                                    setPriority={setEditPriority}
                                    published={editPublished}
                                    setPublished={setEditPublished}
                                    description={editDescription}
                                    setDescription={setEditDescription}
                                    associated={editAssociated}
                                    setAssociated={setEditAssociated}
                                    formLoading={formLoading}
                                    errors={editErrors}
                                    setErrors={setEditErrors}
                                    events={events}
                                    entryId={selectedTallerWebinar.id}
                                ></TallerWebinarForm>
                            </>
                        }
                    ></Modal>
                }
                {selectedTallerWebinar &&
                    <Modal
                        open={suspendModal}
                        title={(selectedTallerWebinar.published ? "Ocultar " : "Activar ") + (selectedTallerWebinar ? selectedTallerWebinar.kind : "")}
                        handleClose={handleCloseSuspendModal}
                        onClickMainButton={() => handleToggleTalleresYWebinarsVisibility(selectedTallerWebinar.id, !selectedTallerWebinar.published)}
                        mainButtonText={selectedTallerWebinar.published ? "Ocultar" : "Activar"}
                        width={"md"}
                        cancelButton={true}
                        startIcon={selectedTallerWebinar.published ? <VisibilityOffIcon></VisibilityOffIcon> : <RemoveRedEyeIcon></RemoveRedEyeIcon>}
                        content={
                            <>
                                <Typography variant="p" textAlign={"justify"} mb={"20px"}>
                                    ¿Está segur@ que desea {selectedTallerWebinar.published ? "ocultar" : "activar"} el {selectedTallerWebinar ? selectedTallerWebinar.kind : ""} seleccionado?
                                </Typography>
                                <Typography variant="p" textAlign={"center"} mb={"20px"}>
                                    <b>{selectedTallerWebinar.title}</b>
                                </Typography>
                                <Typography variant="p" textAlign={"justify"} mb={"20px"}>
                                    {selectedTallerWebinar.published ?
                                        "El " + selectedTallerWebinar.kind + " seleccionado será ocultado y ya no será visible en RedFID Aprendizaje Profesional. Los usuarios autenticados no podrán visualizarlo. Esta acción es reversible."
                                        :
                                        "El " + selectedTallerWebinar.kind + " seleccionado será activado en RedFID Aprendizaje Profesional, y pasará a ser visible por los usuarios autenticados. Esta acción es reversible."
                                    }
                                </Typography>
                            </>
                        }
                    ></Modal>
                }
                {selectedTallerWebinar &&
                    <Modal
                        open={deleteModal}
                        title={"Eliminar " + (selectedTallerWebinar ? selectedTallerWebinar.kind : "")}
                        handleClose={handleCloseDeleteModal}
                        onClickMainButton={() => handleDeleteTalleresYWebinars(selectedTallerWebinar.id)}
                        mainButtonText={"Eliminar"}
                        width={"md"}
                        cancelButton={true}
                        startIcon={<DeleteForever></DeleteForever>}
                        content={
                            <>
                                <Typography variant="p" textAlign={"justify"} mb={"20px"}>
                                    ¿Está segur@ que desea eliminar el {selectedTallerWebinar ? selectedTallerWebinar.kind : ""} seleccionado?
                                </Typography>
                                <Typography variant="p" textAlign={"center"} mb={"20px"}>
                                    <b>{selectedTallerWebinar.title}</b>
                                </Typography>
                                <Typography variant="p" textAlign={"justify"} mb={"20px"}>
                                    El {selectedTallerWebinar.kind} seleccionado será eliminado de RedFID Aprendizaje Profesional. Esta acción es irreversible.
                                </Typography>
                            </>
                        }
                    ></Modal>
                }
            </Box >
        </>
    );

};

export default TalleresYWebinars;