import React, { useState } from 'react';
import { Avatar, Box, Typography } from '@mui/material';
import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { esES } from '@mui/x-data-grid/locales';
import { formatDateFromUnix } from '../../../utils/formatters';

const UniversityMembersTable = ({
    users
}) => {

    const [paginationModel, setPaginationModel] = useState({ pageSize: 10, page: 0 });

    const columns = [

        {
            field: 'picture',
            headerName: 'Foto',
            sortable: false,
            width: 100,
            disableColumnMenu: true,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
                params.row.discourse_data == null ? 
                <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                    <Avatar alt="Foto" style={{ marginTop: "5px", width: 40, height: 40, borderRadius: 50 }} />
                </Box> 
                : params.row.discourse_data.avatar_template == null ? 
                <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                    <Avatar alt="Foto" style={{ marginTop: "5px", width: 40, height: 40, borderRadius: 50 }} /> 
                </Box>
                : 
                <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                    <Avatar src={process.env.REACT_APP_COMUNIDADES_URL + params.row.discourse_data.avatar_template.replace("{size}", "144")} alt="Foto" style={{ marginTop: "5px", width: 40, height: 40, borderRadius: 50 }} />
                </Box>
            )
        },
        { 
            field: 'lastName', 
            disableColumnMenu: true, 
            headerName: 'Apellido', 
            width: 150, 
            align: 'left', 
            headerAlign: 'left' 
        },
        { 
            field: 'firstName', 
            disableColumnMenu: true, 
            headerName: 'Nombre', 
            width: 150,
            align: 'left', 
            headerAlign: 'left' 
        },
        { 
            field: 'username', 
            disableColumnMenu: true, 
            headerName: 'Usuario', 
            width: 150,
            align: 'left', 
            headerAlign: 'left' 
        },
        {
            field: 'enabled',
            disableColumnMenu: true,
            headerName: 'Activo',
            sortable: false,
            width: 80,
            headerAlign: 'center',
            align: 'center',
            sortComparator: (a, b) => {
              return a - b;
            },
            renderCell: (params) => (
              <Box display="flex" alignItems="center" justifyContent="center" height="100%">
                {params.row.enabled
                  ? <CheckCircleIcon sx={{ color: "#088408" }} />
                  : <CancelIcon sx={{ color: "#ff0000" }} />
                }
              </Box>
            )
          },
        {
            field: 'createdTimestamp',
            disableColumnMenu: true,
            headerName: 'Creado',
            width: 180,
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            sortComparator: (a, b) => a - b,
            valueGetter: (params, row) => row.createdTimestamp,
            renderCell: (params) => formatDateFromUnix(params.row.createdTimestamp)
        },
    ];

    function CustomToolbar() {
        return (
            <GridToolbarContainer sx={{ "display": "flex", "justifyContent": "space-between", "padding": "15px 10px" }}>
                <Typography variant='table-title'>Miembros</Typography>
                <Box>
                </Box>
            </GridToolbarContainer>
        );
    }

    return (
        <div style={{ width: '100%', margin: "40px 0" }}>
            <DataGrid
                rows={users}
                columns={columns}
                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                pageSizeOptions={[10]}
                onPaginationModelChange={setPaginationModel}
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'lastName', sort: 'asc' }],
                    },
                    pagination: {
                        paginationModel
                    }
                }}
                slots={{
                    toolbar: CustomToolbar,
                }}
            />
        </div>
    );
};

export default UniversityMembersTable;
