import React, { useState, useEffect } from 'react';
import { Grid, CircularProgress, Typography, Autocomplete, Box, Chip, TextField, Checkbox, Link } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DownloadIcon from '@mui/icons-material/Download';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Indicator from './components/Indicator';
import Modal from './components/Modal';
import EventsTable from './components/tables/EventsTable';
import EventForm from './components/forms/EventForm';
import DownloadCSVEventsForm from './components/forms/DownloadCSVEventsForm';
import { eventsCSV } from '../utils/csvDownload';
import { validateEventForm } from '../utils/validators';
import { formatDateFromDayJStoUnix, formatDateFromUnix, formatDateFromUnixToDayJS, formatUsersIntoSelector } from '../utils/formatters';
import { createEvent } from '../requests/events/createEvent';
import { editEvent } from '../requests/events/editEvent';
import { updateAssistanceEvent } from '../requests/events/updateAssistanceEvent';
import { deleteEvent } from '../requests/events/deleteEvent';
import UserIcon from '@mui/icons-material/Person';
import LineChart from './components/charts/LineChart';
import { api } from '../API';
import BarChart from './components/charts/BarChart';


const Events = ({
    setAlertSuccess,
    setAlertWarning,
    setAlertError,
    alerts,
    setAlerts,
    events,
    setEvents,
    users,
    talleresYWebinars,
    setTalleresYWebinars
}) => {

    const [selectedEvent, setSelectedEvent] = useState(null)
    const [formLoading, setFormLoading] = useState(false);
    const [createModal, setCreateModal] = useState(false);
    const [statsModal, setStatsModal] = useState(false);
    const [downloadCSVModal, setDownloadCSVModal] = useState(false);
    const [infoModal, setInfoModal] = useState(false);
    const [attendantsModal, setAttendantsModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);

    const [title, setTitle] = useState("");
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [place, setPlace] = useState("");
    const [description, setDescription] = useState("");
    const [associated, setAssociated] = useState("none");
    const [errors, setErrors] = useState({});

    const [editTitle, setEditTitle] = useState("");
    const [editStartDate, setEditStartDate] = useState(null);
    const [editEndDate, setEditEndDate] = useState(null);
    const [editPlace, setEditPlace] = useState("");
    const [editDescription, setEditDescription] = useState("");
    const [editAssociated, setEditAssociated] = useState("none");
    const [editErrors, setEditErrors] = useState({});

    const [attendants, setAttendants] = useState([]);

    const [csvTitle, setCsvTitle] = useState(true);
    const [csvStartDate, setCsvStartDate] = useState(true);
    const [csvEndDate, setCsvEndDate] = useState(true);
    const [csvPlace, setCsvPlace] = useState(true);
    const [csvDescription, setCsvDescription] = useState(true);
    const [csvCalendarId, setCsvCalendarId] = useState(true);
    const [csvAssociated, setCsvAssociated] = useState(true);
    const [csvTotalAttendants, setCsvTotalAttendants] = useState(true);
    const [csvAttendants, setCsvAttendants] = useState(true);

    const handleOpenCreateModal = () => {
        setCreateModal(true);
    }

    const handleCloseCreateModal = () => {
        setCreateModal(false);
    }

    const handleOpenStatsModal = () => {
        setStatsModal(true);
    }

    const handleCloseStatsModal = () => {
        setStatsModal(false);
    }

    const handleOpenDownloadCSVModal = () => {
        setDownloadCSVModal(true);
    }

    const handleCloseDownloadCSVModal = () => {
        setDownloadCSVModal(false);
    }

    const handleOpenInfoModal = (thisEvent) => {
        setSelectedEvent(thisEvent);
        setInfoModal(true);
    };

    const handleCloseInfoModal = () => {
        setSelectedEvent(null);
        setInfoModal(false);
    };

    const handleOpenAttendantsModal = (thisEvent) => {
        setSelectedEvent(thisEvent);
        let atts = users.filter(user => thisEvent.attendants.includes(user.username));
        setAttendants(atts.map(attendant => { return { email: attendant.email, label: attendant.lastName + ", " + attendant.firstName, username: attendant.username, firstName: attendant.firstName, lastName: attendant.lastName } }));
        setAttendantsModal(true);
    }

    const handleCloseAttendantsModal = () => {
        setSelectedEvent(null);
        setAttendants([]);
        setAttendantsModal(false);
    }

    const handleOpenEditModal = (thisEvent) => {
        setSelectedEvent(thisEvent);
        setEditTitle(thisEvent.title);
        setEditStartDate(formatDateFromUnixToDayJS(thisEvent.startDate));
        setEditEndDate(formatDateFromUnixToDayJS(thisEvent.endDate));
        setEditPlace(thisEvent.place);
        setEditDescription(thisEvent.description);
        setEditAssociated(thisEvent.associated);
        setEditModal(true);
    };

    const handleCloseEditModal = () => {
        setSelectedEvent(null);
        setEditModal(false);
    };

    const handleOpenDeleteModal = (thisEvent) => {
        setSelectedEvent(thisEvent);
        setDeleteModal(true);
    }

    const handleCloseDeleteModal = () => {
        setSelectedEvent(null);
        setDeleteModal(false);
    };

    const handleCreateEvent = async () => {
        const [isValid, tempErrors] = validateEventForm(
            title,
            startDate,
            endDate,
            place,
            description,
            associated
        );
        if (!isValid) {
            setErrors(tempErrors);
            return;
        }
        setFormLoading(true);
        let options = {
            title: title,
            startDate: formatDateFromDayJStoUnix(startDate),
            endDate: formatDateFromDayJStoUnix(endDate),
            place: place,
            description: description,
            associated: associated
        }
        const response_create = await createEvent(api, options);
        setEvents(response_create.events);
        setTalleresYWebinars(response_create.talleresYWebinars);
        setFormLoading(false);
        handleCloseCreateModal();
        setTitle("");
        setStartDate(null);
        setEndDate(null);
        setPlace("");
        setDescription("");
        setAssociated("none");
        setAlertSuccess(true);
        setAlerts({ ...alerts, "success": { "title": `¡Evento creado!`, "text": `El evento ${title} se ha creado correctamente.` } });
    }

    const handleDownloadCSV = () => {
        const options = {
            title: csvTitle,
            startDate: csvStartDate,
            endDate: csvEndDate,
            place: csvPlace,
            description: csvDescription,
            calendarId: csvCalendarId,
            associated: csvAssociated,
            totalAttendants: csvTotalAttendants,
            attendants: csvAttendants
        }
        eventsCSV(events, options);
        handleCloseDownloadCSVModal();
        setAlertSuccess(true);
        setAlerts({ ...alerts, "success": { "title": "¡Archivo descargado!", "text": "Los datos se han descargado correctamente." } });
    }

    const handleEditEvent = async () => {
        const [isValid, tempErrors] = validateEventForm(
            editTitle,
            editStartDate,
            editEndDate,
            editPlace,
            editDescription,
            editAssociated
        );
        if (!isValid) {
            setEditErrors(tempErrors);
            return;
        }
        setFormLoading(true);
        let options = {
            id: selectedEvent.id,
            title: editTitle,
            startDate: formatDateFromDayJStoUnix(editStartDate),
            endDate: formatDateFromDayJStoUnix(editEndDate),
            place: editPlace,
            description: editDescription,
            associated: editAssociated,
            attendants: selectedEvent.attendants
        }
        const response = await editEvent(api, options);
        setEvents(response.events);
        setTalleresYWebinars(response.talleresYWebinars);
        setFormLoading(false);
        handleCloseEditModal();
        setAlertSuccess(true);
        setAlerts({ ...alerts, "success": { "title": `¡Evento editado!`, "text": `El evento ${editTitle} se ha editado correctamente.` } });
    }

    const handleUpdateAssistance = async () => {
        setFormLoading(true);
        let options = {
            id: selectedEvent.id,
            title: selectedEvent.title,
            attendants: attendants.map(attendant => attendant.username)
        }
        const response = await updateAssistanceEvent(api, options);
        setEvents(response.events);
        setFormLoading(false);
        handleCloseAttendantsModal();
        setAlertSuccess(true);
        setAlerts({ ...alerts, "success": { "title": `¡Asistencia actualizada!`, "text": `La asistencia al evento ${options.title} se ha actualizado correctamente.` } });
    }

    const handleDeleteEvent = async () => {
        setFormLoading(true);
        let options = {
            id: selectedEvent.id
        }
        const response = await deleteEvent(api, options);
        setEvents(response.events);
        setTalleresYWebinars(response.talleresYWebinars);
        setFormLoading(false);
        handleCloseDeleteModal();
        setAlertSuccess(true);
        setAlerts({ ...alerts, "success": { "title": `¡Evento eliminado!`, "text": `El evento ${selectedEvent.title} se ha eliminado correctamente.` } });
    }

    return (
        <>
            <Grid spacing={2} container justifyContent={"center"}>
                <Grid item xs={3}>
                    <Indicator title="Total eventos" number={
                        Object.keys(events).length
                    } titleSize="small" subtitleSize="none" helpMessage={
                        "Número total de eventos a los que se les ha registrado asistencia."
                    }></Indicator>
                </Grid>
                <Grid item xs={3}>
                    <Indicator title="Asistentes" number={
                        Object.values(events).reduce((acc, event) => acc.add(...event.attendants.filter(attendant => users.find(user => user.username === attendant && user.attributes?.is_formador?.[0] === "1"))), new Set()).size
                    } titleSize="small" subtitleSize="none" helpMessage={
                        "Número total de formadores que han asistido a al menos un evento."
                    }></Indicator>
                </Grid>
                <Grid item xs={3}>
                    <Indicator title="Asistencias totales" number={
                        Object.values(events).reduce((acc, event) => acc + event.attendants.length, 0)
                    } titleSize="small" subtitleSize="none" helpMessage={
                        "Número total de asistencias de formadores a lo largo de todos los eventos."
                    }></Indicator>
                </Grid>
            </Grid>
            <Grid display={"flex"} alignItems={"center"} justifyContent={"space-between"} spacing={2} container>
                {(!events || !users) ? <Box mt={"100px"} width={"100%"} textAlign="center"><CircularProgress sx={{ "color": "#40b4ba" }}></CircularProgress></Box> :
                    <EventsTable
                        users={users}
                        events={events}
                        handleOpenCreateModal={handleOpenCreateModal}
                        handleOpenStatsModal={handleOpenStatsModal}
                        handleOpenDownloadCSVModal={handleOpenDownloadCSVModal}
                        handleOpenInfoModal={handleOpenInfoModal}
                        handleOpenAttendantsModal={handleOpenAttendantsModal}
                        handleOpenEditModal={handleOpenEditModal}
                        handleOpenDeleteModal={handleOpenDeleteModal}
                    />
                }
            </Grid>
            <Modal
                open={createModal}
                handleClose={handleCloseCreateModal}
                title="Crear evento"
                content={
                    <>
                        <Typography variant="p">
                            Aquí puede crear un nuevo evento. Los eventos se sincronizan con Google Calendar.
                        </Typography>
                        <EventForm
                            title={title}
                            setTitle={setTitle}
                            startDate={startDate}
                            setStartDate={setStartDate}
                            endDate={endDate}
                            setEndDate={setEndDate}
                            place={place}
                            setPlace={setPlace}
                            description={description}
                            setDescription={setDescription}
                            associated={associated}
                            setAssociated={setAssociated}
                            formLoading={formLoading}
                            errors={errors}
                            setErrors={setErrors}
                            talleresYWebinars={talleresYWebinars}
                            entryId={null}
                        ></EventForm>
                    </>
                }
                onClickMainButton={handleCreateEvent}
                mainButtonText="Crear"
                width={'lg'}
                cancelButton={true}
                mainButtonDisabled={formLoading}
                startIcon={<AddCircleOutlineIcon></AddCircleOutlineIcon>}
            ></Modal>
            <Modal
                open={statsModal}
                handleClose={handleCloseStatsModal}
                title="Estadísticas de eventos"
                content={
                    <>
                        <Grid spacing={2} container>
                            <Grid item xs={12}>
                                <Typography variant="h6">Asistencia mensual a eventos</Typography>
                                <LineChart
                                    id={"talleres-by-attendants"}
                                    height={"300px"}
                                    tooltipFormatter={(value) => value + " asistencias"}
                                    timeInterval={"month"}
                                    data={() => {
                                        let out = [];
                                        for (let e of events) {
                                            let date = new Date(e.startDate).toISOString().slice(0, 10).replace(/-/g, "");
                                            out.push({
                                                "date": date,
                                                "value": parseInt(e.attendants.length),
                                                "color": "#eb947e"
                                            });
                                        }
                                        out = out.sort((a, b) => b.date - a.date);
                                        return out;
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h6">Eventos con mayor asistencia</Typography>
                                <BarChart
                                    id={"events-by-attendants"}
                                    sort={true}
                                    height={"400px"}
                                    horizontal={true}
                                    data={() => {
                                        let out = [];
                                        for (let e of events) {
                                            out.push({
                                                "label": e.title,
                                                "value": e.attendants.length,
                                                "color": "#40b4ba"
                                            });
                                        }
                                        out = out.sort((a, b) => b.value - a.value).slice(0, 10);
                                        return out;
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </>
                }
                onClickMainButton={handleCloseStatsModal}
                mainButtonText="OK"
                hideMainButton={true}
                width={'lg'}
                cancelButton={false}
                startIcon={null}
            ></Modal>
            <Modal
                open={downloadCSVModal}
                handleClose={handleCloseDownloadCSVModal}
                title="Descargar datos"
                content={
                    <>
                        <Typography variant="p" mb={"20px"}>
                            Se descargará un archivo en formato .csv con la información de todos los eventos de RedFID.
                            Por favor, seleccione los campos que desea incluir:
                        </Typography>
                        <DownloadCSVEventsForm
                            csvTitle={csvTitle}
                            setCsvTitle={setCsvTitle}
                            csvStartDate={csvStartDate}
                            setCsvStartDate={setCsvStartDate}
                            csvEndDate={csvEndDate}
                            setCsvEndDate={setCsvEndDate}
                            csvPlace={csvPlace}
                            setCsvPlace={setCsvPlace}
                            csvDescription={csvDescription}
                            setCsvDescription={setCsvDescription}
                            csvCalendarId={csvCalendarId}
                            setCsvCalendarId={setCsvCalendarId}
                            csvAssociated={csvAssociated}
                            setCsvAssociated={setCsvAssociated}
                            csvTotalAttendants={csvTotalAttendants}
                            setCsvTotalAttendants={setCsvTotalAttendants}
                            csvAttendants={csvAttendants}
                            setCsvAttendants={setCsvAttendants}
                        ></DownloadCSVEventsForm>
                    </>
                }
                onClickMainButton={handleDownloadCSV}
                mainButtonText="Descargar"
                mainButtonDisabled={formLoading}
                width={'md'}
                cancelButton={true}
                startIcon={<DownloadIcon />}
            ></Modal>
            {selectedEvent &&
                <>
                    <Modal
                        open={infoModal}
                        handleClose={handleCloseInfoModal}
                        title="Información detallada"
                        content={
                            <>
                                <Grid container columnSpacing={2}>
                                    <Grid item mb={"10px"} xs={12} display={"flex"} flexDirection={"column"} justifyContent={"space-between"}>
                                        <Typography variant='p' mb={"10px"}><b>ID en Calendar:</b> {selectedEvent.calendar_id}</Typography>
                                        <Typography variant='p' mb={"10px"}><b>Descripción:</b> {selectedEvent.description}</Typography>
                                    </Grid>
                                    <Grid item mb={"10px"} xs={12} md={6} display={"flex"} flexDirection={"column"} justifyContent={"space-between"}>
                                        <Typography sx={{ "wordBreak": "break-all" }} variant='p' mb={"10px"}><b>Ubicación: </b>{selectedEvent.place.startsWith("http") ? <Link color="#40b4ba" href={selectedEvent.place} target="_blank">{selectedEvent.place}</Link> : selectedEvent.place}</Typography>
                                        <Typography variant='p' mb={"10px"}><b>Asistentes: </b>{selectedEvent.attendants.length}</Typography>
                                    </Grid>
                                    <Grid item mb={"10px"} xs={12} md={6} display={"flex"} flexDirection={"column"}>
                                        <Typography variant='p' mb={"10px"}><b>Inicio: </b>{formatDateFromUnix(selectedEvent.startDate)}</Typography>
                                        <Typography variant='p' mb={"10px"}><b>Fin: </b>{formatDateFromUnix(selectedEvent.endDate)}</Typography>
                                    </Grid>
                                </Grid>
                            </>
                        }
                        onClickMainButton={handleCloseInfoModal}
                        mainButtonText="OK"
                        width={'sm'}
                        mainButtonDisabled={true}
                        cancelButton={false}
                        startIcon={null}
                    ></Modal>
                    <Modal
                        open={attendantsModal}
                        handleClose={handleCloseAttendantsModal}
                        title="Asistentes"
                        content={
                            <>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography variant="p">
                                            Aquí puede actualizar la asistencia al evento <b>{selectedEvent.title}</b>.
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} mt={"20px"}>
                                        <Autocomplete
                                            multiple
                                            value={attendants}
                                            onChange={(event, newValue) => {
                                                setAttendants(newValue.map((user) => { return { email: user.email, label: user.label, username: user.username, firstName: user.firstName, lastName: user.lastName } }));
                                                setErrors({ ...errors, attendants: "" });

                                            }}
                                            id="tags-outlined"
                                            options={formatUsersIntoSelector(users.filter(u => u.attributes && u.attributes.is_formador && u.attributes.is_formador[0] === "1"))}
                                            getOptionLabel={(user) => user.label + " (" + user.username + ")"}
                                            isOptionEqualToValue={(option, value) => { return option.username === value.username }}
                                            getOptionKey={(user) => user.email}
                                            defaultValue={undefined}
                                            disableCloseOnSelect
                                            noOptionsText="No se encontraron usuarios."
                                            renderOption={(props, option) => {
                                                return (
                                                    <li {...props} key={option.username}>
                                                        <Checkbox
                                                            style={{ marginRight: 8 }}
                                                            checked={attendants.map(attendant => attendant.username).includes(option.username)}
                                                        />
                                                        {option.label}
                                                    </li>
                                                )
                                            }}
                                            renderTags={(tagValue, getTagProps) => {
                                                return tagValue.map((option, index) => (
                                                    <Chip variant="orange" {...getTagProps({ index })} key={option.username} label={option.label} />
                                                ))
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Formadores"
                                                    placeholder=""
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography fontSize={"1em"} variant="h6" mt="10px" textAlign={"center"}>
                                            Asistentes actuales: {attendants.length}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </>
                        }
                        onClickMainButton={handleUpdateAssistance}
                        mainButtonText="Actualizar"
                        mainButtonDisabled={formLoading}
                        width={'md'}
                        cancelButton={true}
                        startIcon={<UserIcon />}
                    ></Modal>
                    <Modal
                        open={editModal}
                        handleClose={handleCloseEditModal}
                        title="Editar evento"
                        content={
                            <>
                                <Typography variant="p">
                                    Aquí puede editar el evento <b>{selectedEvent.title}</b>.
                                </Typography>
                                <EventForm
                                    title={editTitle}
                                    setTitle={setEditTitle}
                                    startDate={editStartDate}
                                    setStartDate={setEditStartDate}
                                    endDate={editEndDate}
                                    setEndDate={setEditEndDate}
                                    place={editPlace}
                                    setPlace={setEditPlace}
                                    description={editDescription}
                                    setDescription={setEditDescription}
                                    associated={editAssociated}
                                    setAssociated={setEditAssociated}
                                    formLoading={formLoading}
                                    errors={editErrors}
                                    setErrors={setEditErrors}
                                    talleresYWebinars={talleresYWebinars}
                                    entryId={selectedEvent.id}
                                ></EventForm>
                            </>
                        }
                        onClickMainButton={handleEditEvent}
                        mainButtonText="Editar"
                        mainButtonDisabled={formLoading}
                        width={'lg'}
                        cancelButton={true}
                        startIcon={<EditIcon></EditIcon>}
                    ></Modal>
                    <Modal
                        open={deleteModal}
                        handleClose={handleCloseDeleteModal}
                        title="Eliminar evento"
                        content={
                            <>
                                <Typography variant="p" mb={"20px"}>
                                    ¿Está seguro de que desea eliminar el evento <b>{selectedEvent.title}</b>? Se eliminarán los registros de asistencia y el evento de Calendar.
                                </Typography>
                                {selectedEvent.associated !== 0 &&
                                    <>
                                        <Typography variant="p" mb={"20px"}>
                                            <b>Nota:</b> Este evento está asociado al siguiente {talleresYWebinars.filter(taller => taller.id === selectedEvent.associated)[0].kind} de Aprendizaje
                                            Profesional:
                                        </Typography>
                                        <Typography textAlign="center" fontWeight={"bold"} variant="p" mb={"20px"}>
                                            {talleresYWebinars.filter(taller => taller.id === selectedEvent.associated)[0].title}
                                        </Typography>
                                        <Typography variant="p" mb={"20px"}>
                                            Se eliminará la asociación, pero no se eliminará el {talleresYWebinars.filter(taller => taller.id === selectedEvent.associated)[0].kind} en sí.
                                        </Typography>
                                    </>
                                }
                            </>
                        }
                        onClickMainButton={handleDeleteEvent}
                        mainButtonText="Eliminar"
                        mainButtonDisabled={formLoading}
                        width={'md'}
                        cancelButton={true}
                        startIcon={<DeleteForeverIcon />}
                    ></Modal>
                </>
            }
        </>
    );
}

export default Events;
